
(function () {
	"use strict";

	/**
	 * matomo tracking functions
	 */

	const linkCareerInteresed = $("a[data-wt='career-jetzt-bewerben-btn-bottom']")
	linkCareerInteresed.click(function(event) {
		_mtm.push({event: 'clickCareerInterested'})
	});

	const linkApplyMiddle = $("a[data-wt='career-jetzt-beim-jobanbieter-bewerben-btn-middle']")
	linkApplyMiddle.click(function(event) {
		_mtm.push({event: 'clickApplyMiddle'})
	});

	const linkApplyTop = $("a[data-wt='career-jetzt-beim-jobanbieter-bewerben-btn-top']")
	linkApplyTop.click(function(event) {
		_mtm.push({event: 'clickApplyTop'})
	});
})
();