/**
 *    Copyright 2022 Beaufort 8 GmbH

   Licensed under the Apache License, Version 2.0 (the "License");
   you may not use this file except in compliance with the License.
   You may obtain a copy of the License at

       http://www.apache.org/licenses/LICENSE-2.0

   Unless required by applicable law or agreed to in writing, software
   distributed under the License is distributed on an "AS IS" BASIS,
   WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
   See the License for the specific language governing permissions and
   limitations under the License.

 */
(function () {
	'use strict';

function domReady(fn) {
	if (document.readyState === "complete") {
		fn();
	} else {
		document.addEventListener("load", fn());
	}
}

/**
 * Enables dynamic source switching for responsive images in card teasers
 */

function initCards() {

    var visibleCards = document.querySelectorAll('.cards-grid--magazine:not(.cards-grid--trio) .card-magazine.card-magazine--flexible:not([hidden=true])');

	for (let b = 0; b < visibleCards.length; b++) {
		const card = visibleCards[b];
		if (card.hasAttribute('data-cached-source')) {
			card.classList.remove('card-magazine--large');
			const pictureElem = card.querySelector(".card-background picture");
			const firstSource = card.querySelector(".card-background picture source");
			const sourceToInsert = document.createElement("source");
			sourceToInsert.innerHTML = card.getAttribute('data-cached-source');
			pictureElem.insertBefore(sourceToInsert.querySelector('source'), pictureElem.firstChild);
			card.removeAttribute('data-cached-source');
        }

		let count = b + 1;
		if (count == 1 || count == 4 || (count - 4) % 9 == 0 || (count - 10) % 9 == 0 || count % 9 == 0) {
			const source = card.querySelector(".card-background picture source");

			if (source.outerHTML) {
				card.setAttribute('data-cached-source', source.outerHTML);
				card.classList.add('card-magazine--large');
				source.remove();
			}
			else {
				card.hide();
				console.log('hidden broken card-element')
			}
		}
	}
};

domReady(initCards);

})();
