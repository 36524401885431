/**
 *    Copyright 2022 Beaufort 8 GmbH

   Licensed under the Apache License, Version 2.0 (the "License");
   you may not use this file except in compliance with the License.
   You may obtain a copy of the License at

       http://www.apache.org/licenses/LICENSE-2.0

   Unless required by applicable law or agreed to in writing, software
   distributed under the License is distributed on an "AS IS" BASIS,
   WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
   See the License for the specific language governing permissions and
   limitations under the License.

 */
(function() {
    "use strict";

    var sliderEl = document.querySelectorAll(".gallery-slider");
    var slideIndexChange = document.querySelectorAll('[data-role="slider-index"]')

    if (sliderEl.length) {
        for (let el = 0; el < sliderEl.length; el++) {
            const sliderContainer = sliderEl[el].querySelector(".inner");
            const controlsContainer = sliderEl[el].querySelector(".gallery-slider-prevnext");
            const navContainer = sliderEl[el].querySelector(".gallery-slider-controls");

            // Init slider
            var slider = tns({
                container: sliderContainer,
                items: 1,
                lazyload: true,
                controlsContainer: controlsContainer,
                navContainer: navContainer,
                speed: 500,
                slideBy: "page",
                touch: true,
                mouseDrag: true,
                arrowKeys: true
            });

            if(slider.getInfo().pages > 9) {
                sliderEl[el].querySelector('.gallery-slider-controls').classList.add('_hide');
            }
        }

        slider.events.on('indexChanged', (info, eventName) => {
            if (slideIndexChange.length) {
                for (let i = 0; i < slideIndexChange.length; i++) {
                    const sliderIndex = slideIndexChange[i];
                    sliderIndex.innerHTML = info.displayIndex;
                }  
            }
        })
    }    

    $('[data-fancybox="gallery"]').fancybox({
        buttons: [
            //"zoom",
            //"share",
            //"slideShow",
            //"fullScreen",
            //"download",
            //"thumbs",
            "close"
        ],
        btnTpl: {
            download:
                '<a download data-fancybox-download class="fancybox-button fancybox-button--download" title="{{DOWNLOAD}}" href="javascript:;">' +
                '<svg viewBox="0 0 40 40">' +
                '<path d="M13,16 L20,23 L27,16 M20,7 L20,23 M10,24 L10,28 L30,28 L30,24" />' +
                "</svg>" +
                "</a>",

            zoom:
                '<button data-fancybox-zoom class="fancybox-button fancybox-button--zoom" title="{{ZOOM}}">' +
                '<svg viewBox="0 0 40 40">' +
                '<path d="M18,17 m-8,0 a8,8 0 1,0 16,0 a8,8 0 1,0 -16,0 M24,22 L31,29" />' +
                "</svg>" +
                "</button>",

            close:
                '<button data-fancybox-close class="fancybox-button fancybox-button--close" title="{{CLOSE}}">' +
                '<svg viewBox="0 0 40 40">' +
                '<path d="M10,10 L30,30 M30,10 L10,30" />' +
                "</svg>" +
                "</button>",

            // This small close button will be appended to your html/inline/ajax content by default,
            // if "smallBtn" option is not set to false
            smallBtn:
                '<button data-fancybox-close class="fancybox-close-small" title="{{CLOSE}}"><svg viewBox="0 0 32 32"><path d="M10,10 L22,22 M22,10 L10,22"></path></svg></button>',

            // Arrows
            arrowLeft:
                '<a data-fancybox-prev class="fancybox-button fancybox-button--arrow_left" title="{{PREV}}" href="javascript:;">' +
                '<svg height="24" viewBox="0 0 15 24" width="15" xmlns="http://www.w3.org/2000/svg">' +
                '<path d="m14.8064516 1.93431818-1.5963206-1.38886363-13.210131 11.45454545 13.210131 11.4545455 1.5963206-1.3817046-11.60609878-10.0728409z" fill="#fff" transform=""/>' +
                "</svg>" +
                "</a>",

            arrowRight:
                '<a data-fancybox-next class="fancybox-button fancybox-button--arrow_right" title="{{NEXT}}" href="javascript:;">' +
                '<svg height="24" viewBox="0 0 17 24" width="17" xmlns="http://www.w3.org/2000/svg">' +
                '<path d="m136.548387 1.93431818 1.77369-1.38886363 14.677923 11.45454545-14.677923 11.4545455-1.77369-1.3817046 12.895665-10.0728409z" fill="#fff" transform="translate(-136)"/>' +
                "</svg>" +
                "</a>"
        },
        transitionEffect: "slide",
        lang: "de"
    });
})();
