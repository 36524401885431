/* Code now lives in its own npm module: ./node_modules/a11y-accordion-tabs/a11y-accordion-tabs.js */

// Additional code for Accordion-opening, if entered by anchor-link
$(document).ready(function () {
	openAccordion();
});
window.addEventListener('hashchange', function () {
	openAccordion();
});

function openAccordion() {
	let hash = window.location.hash;

	if (hash) {
		let accordiontab = $(hash);
		let accordioncontent = $(hash + ' .content');
		if (accordiontab.length && accordiontab.hasClass('tabs-panel')) {
			accordiontab.removeClass('is-hidden');
			accordiontab.addClass('is-open');

			accordioncontent.removeClass('is-hidden');
			accordioncontent.addClass('is-open');
			accordioncontent.attr({'aria-hidden': 'false'})
		}
	}
}