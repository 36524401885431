/**
 *    Copyright 2022 Beaufort 8 GmbH

   Licensed under the Apache License, Version 2.0 (the "License");
   you may not use this file except in compliance with the License.
   You may obtain a copy of the License at

       http://www.apache.org/licenses/LICENSE-2.0

   Unless required by applicable law or agreed to in writing, software
   distributed under the License is distributed on an "AS IS" BASIS,
   WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
   See the License for the specific language governing permissions and
   limitations under the License.

 */

/*! Search form */
(function (global) {
    "use strict";

    var ESCAPE_KEY = 27;

    function SearchForm(trigger, target) {
        // Prebind the functions that will be bound in addEventListener and
        // removeEventListener to avoid losing references
        this._show = this.show.bind(this);
        this._hide = this.hide.bind(this);
        this._toggle = this.toggle.bind(this);
        this._bindKeypress = this._bindKeypress.bind(this);

        this.init(trigger, target);
    }

    SearchForm.prototype.init = function (trigger, target) {
        var _self = this;

        this.shown = false;

        this._trigger = trigger;
        this._target = target;

        this._triggerelements = document.querySelectorAll('.j-search-trigger');
        this._targetelement = document.querySelector(this._target);

        this._closebutton = document.querySelector(
            ".search-form .button--close"
        );

		if (!this._triggerelements) {
            return false;
        }

        if (!this._closebutton) {
            return false;
        }

		for (let t = 0; t < this._triggerelements.length; t++) {
			const trigger = this._triggerelements[t];
			trigger.addEventListener('click', this._toggle);
		}

		this._closebutton.addEventListener('click', this._hide);

        document.body.appendChild(this._targetelement);

        document.addEventListener("click", function (event) {
            if (
                $(event.target).parents(".j-search-form").length < 1 &&
                !$(event.target).hasClass("j-search-trigger") &&
                !$(event.target).parents(".j-search-trigger").length
            ) {
                _self.hide(event);
            }
        });

        return this;
    };

    SearchForm.prototype.toggle = function (event) {
        event.preventDefault();
        if ($(".j-search-form").hasClass("visually-hidden")) {
            this.show(event);
        } else {
            this.hide(event);
        }
    };

    SearchForm.prototype.show = function (event) {
        // If the dialog is already open, abort
        event.preventDefault();

        if (this.shown) {
            return this;
        }
        this.shown = true;

        $(this._target).removeClass("visually-hidden").attr("tabindex", "0");
        $(".j-search-form button").attr("tabindex", "0");
        $(".j-search-form-input").attr("tabindex", "0").val("").focus();

        document.addEventListener("keydown", this._bindKeypress);
    };

    SearchForm.prototype.hide = function (event) {
        //event.preventDefault();
        // If the dialog is already open, abort
        if (!this.shown) {
            return this;
        }

        this.shown = false;

        $(this._target).addClass("is-exiting");
        setTimeout(() => {
            $(this._target)
                .removeClass("is-exiting")
                .addClass("visually-hidden")
                .attr("tabindex", "-1");
        }, 500);

        $(".j-search-form button").attr("tabindex", "-1");
        $(".j-search-form-input").attr("tabindex", "-1").off("focus");
        document.removeEventListener("keydown", this._bindKeypress);
    };

    SearchForm.prototype._bindKeypress = function (event) {
        // If the dialog is shown and the ESCAPE key is being pressed, prevent any
        // further effects from the ESCAPE key and hide the dialog
        if (this.shown && event.which === ESCAPE_KEY) {
            event.preventDefault();
            this.hide(event);
        }
    };

    var searchForm = new SearchForm(".j-search-trigger", ".j-search-form");
})();
