
	//matomo tracking
	$(document).ready(function() {
		const linkElementsArticle = $('.teaser-related-articles').find('a.wt');

		linkElementsArticle.each(function () {
			$(this).on("click", function () {
				_mtm.push({event: 'clickWeitereBwegtStories'});
			});
		});

		const linkElementsCards = $('.cards-grid').find('a.wt');

		linkElementsCards.each(function () {
			$(this).on("click", function () {
				_mtm.push({event: 'clickAuchInteressant'});
			});
		});

		const linkElementsInText = $('.page-content--magazine').find('.textblock').find('a');

		linkElementsInText.each(function (index, element){
			const linkTextURL = $(element).attr('href');
			$(this).on("click", function () {
				_mtm.push({event: 'clickLinkText', linktext: linkTextURL});
			});
		});
	});
