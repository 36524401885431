/**
 *    Copyright 2022 Beaufort 8 GmbH

   Licensed under the Apache License, Version 2.0 (the "License");
   you may not use this file except in compliance with the License.
   You may obtain a copy of the License at

       http://www.apache.org/licenses/LICENSE-2.0

   Unless required by applicable law or agreed to in writing, software
   distributed under the License is distributed on an "AS IS" BASIS,
   WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
   See the License for the specific language governing permissions and
   limitations under the License.

 */
(function() {
    'use strict';
    //Variables
    const Container = document.querySelector('.quality-ranking');
    const tableContainer = document.querySelector('[data-role="table"]');
    const table = document.querySelector('[data-role="table-column"]');
    const downloadBtn = document.querySelector('[data-role="download-ranking"]');
    const rankingTitle = document.querySelectorAll('[data-role="ranking-title"]');
    const tooltipEl = document.querySelectorAll('[data-role="tooltip"]');
    const actionDescription = document.querySelector('[data-action="toggle-description"]');
    const filters = document.querySelector('[data-action="filter-selection"]');
    const jsonURL = "/fileadmin/static/assets/json/quality-ranking-lp/ranking-table-values-2023-1st-half.json";
    const jumpToTable = document.querySelectorAll('[data-action="jump-to-table"]');
    const sortingAction = document.querySelectorAll('[data-action="sort-by-rank"]');
	let textSortDESC = "Absteigend sortieren:";
	let textSortASC = "Aufsteigend sortieren:";
    let conditionalCssClass = "";

    //Init
    const initTable = () => {
        if (Container == null) {
            return;
        }

        filterSelection();
        criteriaSorting();
        toggleDescription();
        toggleTooltip();
        loadJsonData(jsonURL, null, null);
    };

    const toggleDescription = () => {
        actionDescription.addEventListener('click', (event) => {
            event.preventDefault();
            event.currentTarget.parentElement.classList.toggle('quality-ranking__open-header--open');
            document.querySelector('[data-role="table-header"]').classList.toggle('quality-ranking__column--header--open');
            event.currentTarget.querySelector('[data-role="open"]').classList.toggle('_hide');
            event.currentTarget.querySelector('[data-role="close"]').classList.toggle('_hide');
        })
    }

    const jumpToTableColumn = () => {
        let tableLabel = document.querySelectorAll('[data-label]');
        let slideIndex = null;
        let selectedSlide = null;
        let highlightUl = null;
        let slideColumn = null;

        for (let x = 0; x < jumpToTable.length; x++) {
            const el = jumpToTable[x];

            el.addEventListener('click', (event) => {
                event.preventDefault();
                let mapID = event.target.closest('.extension-map-info').getAttribute('id');
                for (let j = 0; j < tableLabel.length; j++) {
                    const label = tableLabel[j];
                    slideColumn = label.closest('ul');

                    if(label.hasAttribute('data-label')) {
                        let labelID = label.getAttribute('data-label');

                        if(mapID == labelID) {
                            selectedSlide = label.closest('.slick-slide');
                            if(selectedSlide) {
                                $('html,body').animate({scrollTop: $(tableContainer).offset().top - 140}, 'slow');
                                if(selectedSlide.hasAttribute("data-slick-index")) {
                                    slideIndex = selectedSlide.getAttribute('data-slick-index');
                                    // add highlight
                                    highlightUl = selectedSlide.children[0].children[0];

                                    //make column highlighted
                                    highlightUl.classList.add('quality-ranking__column--highlighted');
                                    setTimeout(() => {
                                        //go to highlighted slider
										$('[data-role="table-slider"]').slick('slickGoTo', slideIndex, true);
										const width = window.innerWidth;
										const offsetTabletAndAbove = -200 * slideIndex;
										const offsetMobile = -134 * slideIndex;
										console.log(slideIndex);
										if(width <= 768) {
											$('.slick-track').css('transform', `translate3d(${offsetMobile}px,0,0)`);
											$('.slick-track').css('transition', 'transform 500ms ease 0s');
										} else if(width <= 991) {
											$('.slick-track').css('transform', `translate3d(${offsetTabletAndAbove}px,0,0)`);
											$('.slick-track').css('transition', 'transform 500ms ease 0s');
										} else if(width >= 992) {
											$('.slick-track').css('transform', `translate3d(${offsetTabletAndAbove}px,0,0)`);
											$('.slick-track').css('transition', 'transform 1300ms ease 0s');
										}
                                    }, 1000)
                                }
                            }
                        } else {
                            if(slideColumn != null) {
                                slideColumn.classList.remove('quality-ranking__column--highlighted');
                            }
                        }
                    }
                }
            })
        }
    }

    const loadJsonData = (url, criteria, ordering) => {
        let xhr = new XMLHttpRequest();

        xhr.addEventListener("load", function() {
            if (this.readyState === 4 && this.status == 200) {
                renderJson(JSON.parse(this.responseText), criteria, ordering);
            }
        });

        xhr.open("GET", url);
        xhr.send();
    }

    const renderJson = (data, criteria, ordering) => {
        let markup = "";
        let sortedByRanking = [];
		let toUpperCaseCriteria = "";

		if(criteria != null) {
			toUpperCaseCriteria = criteria.toUpperCase();
		}

        data.sort(function(a, b) {
            return b.OVERALLRANKING - a.OVERALLRANKING;
        });

        for (let i = 0; i < data.length; i++) {
            const element = data[i];
            sortedByRanking.push({
                ID: [i + 1],
                MAPID: element.MAPID,
                MAPANCHOR: element.MAPANCHOR,
                NAME: element.NAME,
                EVU: element.EVU,
                OVERALLRANKING: element.OVERALLRANKING,
				OVERALLDIFFERENCE: element.OVERALLDIFFERENCE,
                CONTRACT: element.CONTRACT,
                PUNCTUALITY: element.PUNCTUALITY,
                RELIABILITY: element.RELIABILITY,
                TRAINCOMPOSITION: element.TRAINCOMPOSITION,
                CLEANLINESS: element.CLEANLINESS,
                OVERALLSATISFACTION: element.OVERALLSATISFACTION,
            });
        }

        if(criteria != null) {
            let criteriaToUppercase = criteria.toUpperCase();
            sortedByRanking.sort(function(a, b) {
                if(criteriaToUppercase == 'OVERALLRANKING') {
                    if(ordering == 'ASC') {
                        return b.OVERALLRANKING - a.OVERALLRANKING;
                    } else {
                        return a.OVERALLRANKING - b.OVERALLRANKING;
                    }
                } else if(criteriaToUppercase == 'PUNCTUALITY') {
                    if(ordering == 'ASC') {
                        return b.PUNCTUALITY - a.PUNCTUALITY;
                    } else {
                        return a.PUNCTUALITY - b.PUNCTUALITY;
                    }
                } else if(criteriaToUppercase == 'RELIABILITY') {
                    if(ordering == 'ASC') {
                        return b.RELIABILITY - a.RELIABILITY;
                    } else {
                        return a.RELIABILITY - b.RELIABILITY;
                    }
                } else if(criteriaToUppercase == 'TRAINCOMPOSITION') {
                    if(ordering == 'ASC') {
                        return b.TRAINCOMPOSITION - a.TRAINCOMPOSITION;
                    } else {
                        return a.TRAINCOMPOSITION - b.TRAINCOMPOSITION;
                    }
                } else if(criteriaToUppercase == 'CLEANLINESS') {
                    if(ordering == 'ASC') {
                        return b.CLEANLINESS - a.CLEANLINESS;
                    } else {
                        return a.CLEANLINESS - b.CLEANLINESS;
                    }
                } else if(criteriaToUppercase == 'OVERALLSATISFACTION') {
                    if(ordering == 'ASC') {
                        return b.OVERALLSATISFACTION - a.OVERALLSATISFACTION;
                    } else {
                        return a.OVERALLSATISFACTION - b.OVERALLSATISFACTION;
                    }

                }
            });
        }

        for (let x = 0; x < sortedByRanking.length; x++) {
            const element = sortedByRanking[x];

            if (x == 0) {
                markup += `
                <ul class="quality-ranking__column quality-ranking__column--data quality-ranking__column--primary slick-slide" data-role="table-data" data-slick-index="0">
                    <li class="quality-ranking__number__wrapper">
                        <div class="quality-ranking__number">
                            <h2 class="heading--small">${element.ID}.</h2>
                        </div>
                    </li>
					<li class="quality-ranking__wrapper__name">
						<a href="#" class="quality-ranking__name" data-action="filter-map" data-label="${element.MAPANCHOR}">${element.NAME}</a>
					</li>
					<li class="data-contract-name">${element.CONTRACT}</li>
					<li class="data-logo-evu">
                        <div class="quality-ranking__logo">
                            ` + chooseEvuLogo(element.EVU) + `
                        </div>
                    </li>
                    <li class="data-rating ${criteria == null ? 'highlighted' : ''} ${toUpperCaseCriteria == 'OVERALLRANKING' ? 'highlighted' : ''} ">${element.OVERALLRANKING} ${element.OVERALLDIFFERENCE != "" ? `<span> ${element.OVERALLDIFFERENCE} </span>` : ""}</li>
                    <li class="data-punctuality ${toUpperCaseCriteria == 'PUNCTUALITY' ? 'highlighted' : ''}">${element.PUNCTUALITY}</li>
					<li class="data-reliability ${toUpperCaseCriteria == 'RELIABILITY' ? 'highlighted' : ''}">${element.RELIABILITY}</li>
                    <li class="data-train-capacity ${toUpperCaseCriteria == 'TRAINCOMPOSITION' ? 'highlighted' : ''}">${element.TRAINCOMPOSITION}</li>
					<li class="data-cleanliness ${toUpperCaseCriteria == 'CLEANLINESS' ? 'highlighted' : ''}">${element.CLEANLINESS}</li>
					<li class="data-satisfaction ${toUpperCaseCriteria == 'OVERALLSATISFACTION' ? 'highlighted' : ''}">${element.OVERALLSATISFACTION}</li>
                </ul>`;
            }
            break;
        }

        markup += `
            <div class="quality-ranking__slider">
                <div data-role="table-slider">`;

        for (let j = 0; j < sortedByRanking.length; j++) {
            const element = sortedByRanking[j];

            if (j == 1) {
                conditionalCssClass = "quality-ranking__column--secondary";
            } else if (j == 2) {
                conditionalCssClass = "quality-ranking__column--third";
            } else {
                conditionalCssClass = "";
            }

            if (j > 0) {
                markup += `
                            <ul class="quality-ranking__column quality-ranking__column--data ${conditionalCssClass}" data-role="table-data">
                                <li class="quality-ranking__number__wrapper">
                                    <div class="quality-ranking__number">
                                        <h2 class="heading--small">${element.ID}.</h2>
                                    </div>
                                </li>
                                <li class="quality-ranking__wrapper__name">
									<a href="#" class="quality-ranking__name" data-action="filter-map" data-label="${element.MAPANCHOR}">${element.NAME}</a>
                                </li>
								<li class="data-contract-name">${element.CONTRACT}</li>
								<li class="data-logo-evu">
                                    <div class="quality-ranking__logo">
                                        ` + chooseEvuLogo(element.EVU) + `
                                    </div>
                                </li>
                                <li class="data-rating ${criteria == null ? 'highlighted' : ''} ${toUpperCaseCriteria == 'OVERALLRANKING' ? 'highlighted' : ''} ">${element.OVERALLRANKING} ${element.OVERALLDIFFERENCE != "" ? `<span> ${element.OVERALLDIFFERENCE} </span>` : ""}</li>
								<li class="data-punctuality ${toUpperCaseCriteria == 'PUNCTUALITY' ? 'highlighted' : ''}">${element.PUNCTUALITY}</li>
								<li class="data-reliability ${toUpperCaseCriteria == 'RELIABILITY' ? 'highlighted' : ''}">${element.RELIABILITY}</li>
								<li class="data-train-capacity ${toUpperCaseCriteria == 'TRAINCOMPOSITION' ? 'highlighted' : ''}">${element.TRAINCOMPOSITION}</li>
								<li class="data-cleanliness ${toUpperCaseCriteria == 'CLEANLINESS' ? 'highlighted' : ''}">${element.CLEANLINESS}</li>
								<li class="data-satisfaction ${toUpperCaseCriteria == 'OVERALLSATISFACTION' ? 'highlighted' : ''}">${element.OVERALLSATISFACTION}</li>
                            </ul>`;
            }
        }

        markup += `
                </div>
            </div>`;

        $('[data-role="table-slider"]').slick('unslick');
        table.innerHTML = markup;
        initSlider();
		filterMap();
		jumpToTableColumn();
    }

    const filterSelection = () => {
        if (filters) {
            filters.addEventListener('change', (event) => {
                event.preventDefault();
                table.classList.add('animation');
                const target = event.target;
				console.log(target.value);
                const jsonURL = event.target.value;
				setTimeout(() => {
					for (let i = 0; i < rankingTitle.length; i++) {
						const element = rankingTitle[i];
						element.innerHTML = target.options[target.selectedIndex].text;
					}
					// downloadBtn.setAttribute('href', jsonURL);
					loadJsonData(jsonURL, null, null);
					table.classList.remove('animation');
				}, 400);
            })
        }
    }

    const criteriaSorting = () => {
        for (let x = 0; x < sortingAction.length; x++) {
            const element = sortingAction[x];

            element.addEventListener('click', function(e) {
                e.preventDefault();
				table.classList.add('animation');
                const jsonUrl = filters.options[filters.selectedIndex].value;
                const criteria = e.currentTarget.dataset.value;
				const criteriaText = e.currentTarget.children[0].innerHTML;
                let highlightedLi = document.querySelectorAll('[data-role="table-header"] li');
				let activeTarget = e.currentTarget;
				setTimeout(() => {
					// remove highlighted class from default one
					for (let j = 0; j < highlightedLi.length; j++) {
						const element = highlightedLi[j];

						if(element.classList.contains('highlighted')) {
							element.classList.remove('highlighted');
						}

						if(element.children[1] != activeTarget) {
							element.children[1].classList.remove('highlight-arrow-up');
							element.children[1].classList.remove('highlight-arrow-down');
							element.children[1].setAttribute('title', '');
						}
					}

                	// add highlighted class from clicked one

                	activeTarget.closest('li').classList.add('highlighted');
					if(activeTarget.classList.contains('highlight-arrow-down')) {
						activeTarget.classList.add('highlight-arrow-up');
						activeTarget.classList.remove('highlight-arrow-down');
						activeTarget.setAttribute('title', `${textSortDESC} ${criteriaText}`);
						loadJsonData(jsonUrl, criteria, 'DESC');
					} else {
						activeTarget.classList.remove('highlight-arrow-up');
						activeTarget.classList.add('highlight-arrow-down');
						activeTarget.setAttribute('title', `${textSortASC} ${criteriaText}`);
						loadJsonData(jsonUrl, criteria, 'ASC');
					}
					table.classList.remove('animation');
				}, 400)
            })
        }
    }

    const toggleTooltip = () => {
        Array.prototype.forEach.call(document.querySelectorAll('[data-action="toggle-tooltip"]'), function(tooltip) {
            // Toggle the message

            if (tooltip) {
                tooltip.addEventListener('click', function(e) {
                    e.preventDefault();

					window.setTimeout(function() {
                        const tooltipBox = tooltip.children[0].children[1];
                        if (tooltipBox) {
                            if(tooltipBox.classList.contains('_show')) {
                                tooltipBox.classList.remove('_show');
                            } else {
                                // reset tooltip to original state -> hidden
                                for (let index = 0; index < tooltipEl.length; index++) {
                                    const element = tooltipEl[index];

                                    element.classList.remove('_show');
                                }

                                tooltipBox.children[0].scrollTop = 0;
                                tooltipBox.classList.add('_show');
                            }
                        }
                    }, 100);

                });

                // Remove tooltip on ESC
                tooltip.addEventListener('keydown', function(e) {
                    const tooltipBox = tooltip.children[0].children[1];
                    if ((e.keyCode || e.which) === 27) {
                        if (tooltipBox) {
                            tooltipBox.classList.remove('_show');
                        }
                    }

                    if ((e.keyCode || e.which) === 32)
                        e.preventDefault();
                    if (tooltipBox) {
                        if(tooltipBox.classList.contains('_show')) {
                            tooltipBox.classList.remove('_show');
                        } else {
                            tooltipBox.classList.add('_show');
                        }
                    }
                });

                // Remove on blur
                tooltip.addEventListener('blur', function(e) {
                    const tooltipBox = tooltip.children[0].children[1];
                    if (tooltipBox) {
                        tooltipBox.classList.remove('_show');
                    }
                });
            }
        });
    }

    const chooseEvuLogo = (EVU) => {
        let img = "";
        if (EVU == 'Abellio') {
            img = '<img style="max-width: 65px" src="/fileadmin/static/assets/img/quality-ranking/evu-logos/evu-logo-abellio.svg" alt="logo"/>';
        } else if (EVU == 'DB Regio' || EVU == 'DB RegioNetz' || EVU == 'DB RAB' || EVU == 'DB Regio BW' || EVU == 'DB Regio Mitte') {
            img = '<img style="max-width: 35px" src="/fileadmin/static/assets/img/quality-ranking/evu-logos/evu-logo-db.svg" alt="logo"/>';
        } else if (EVU == 'Go-Ahead') {
            img = '<img style="max-width: 80px" src="/fileadmin/static/assets/img/quality-ranking/evu-logos/evu-logo-go-ahead.svg" alt="logo"/>';
        } else if (EVU == 'SWEG' || EVU == 'SWEG (vorm. Abellio)') {
            img = '<img style="max-width: 70px" src="/fileadmin/static/assets/img/quality-ranking/evu-logos/evu-logo-sweg.svg" alt="logo"/>';
        } else if (EVU == 'AVG') {
            img = '<img style="max-width: 70px" src="/fileadmin/static/assets/img/quality-ranking/evu-logos/evu-logo-avg.svg" alt="logo"/>';
        } else if (EVU == 'SAB') {
            img = '<img style="max-width: 130px" src="/fileadmin/static/assets/img/quality-ranking/evu-logos/evu-logo-schwaebische-alb-bahn.svg" alt="logo"/>';
        } else if (EVU == 'SBB') {
			img = '<img style="max-width: 130px; height: 100%" src="/fileadmin/static/assets/img/quality-ranking/evu-logos/logo_efu_sbb.svg" alt="logo"/>';
        }

        return img;
    }

    const filterMap = () => {
        const linkEl = document.querySelectorAll('[data-action="filter-map"]');
        for (let i = 0; i < linkEl.length; i++) {
            const element = linkEl[i];
            element.addEventListener('click', (event) => {
                event.preventDefault();
                let label = event.target.getAttribute('data-label');
                if (label != '') {
                    const mapSelector = document.getElementById('map-selection');
                    for (let index = 1; index < mapSelector.length; index++){
                        let option = mapSelector.options[index];
                        if(option.dataset.id.toLowerCase() == label.toLowerCase()) {
                            mapSelector.value = option.value;

							if ("createEvent" in document) {
								var evt = document.createEvent("HTMLEvents");
								evt.initEvent("change", false, true);
								mapSelector.dispatchEvent(evt);
							}
							else
								mapSelector.fireEvent("onchange");

                        }
                    }
                }
            })
        }
    }

    const initSlider = () => {
        const tableSlider = document.querySelector('[data-role="table-slider"]');
        $(tableSlider).slick({
            infinite: false,
            variableWidth: true,
            slidesToShow: 4,
            slidesToScroll: 3,
            speed: 1300,
            dots: true,
            responsive: [
				{
                    breakpoint: 1280,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        dots: true,
                    }
                },
				{
                    breakpoint: 1220,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        dots: true,
                    }
                },
                {
                    breakpoint: 992,
                    settings: {
                        speed: 500,
                        slidesToShow: 2,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
						speed: 500,
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        appendArrows: $('.slick-slider-nav'),
                        prevArrow: "<button class='slick-prev slick-arrow'><span class='slick-prev-icon' aria-hidden='true'></span></button>",
                        nextArrow: "<button class='slick-next slick-arrow'><span class='slick-next-icon' aria-hidden='true'></span></button>",
                    }
                },
            ]
        });

        $(tableSlider).slick('slickAdd','<div><div><div class="slick-item">added</div></div></div>');

    }

    initTable();
})();
