/**
 *    Copyright 2022 Beaufort 8 GmbH

   Licensed under the Apache License, Version 2.0 (the "License");
   you may not use this file except in compliance with the License.
   You may obtain a copy of the License at

       http://www.apache.org/licenses/LICENSE-2.0

   Unless required by applicable law or agreed to in writing, software
   distributed under the License is distributed on an "AS IS" BASIS,
   WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
   See the License for the specific language governing permissions and
   limitations under the License.

 */

// Inject polyfill if classList not supported for SVG elements.
if (!("classList" in SVGElement.prototype)) {
  Object.defineProperty(SVGElement.prototype, "classList", {
    get: function get() {
      var _this = this;

      return {
        contains: function contains(className) {
          return (
            _this.className.baseVal
              .split(" ")
              .indexOf(className) !== -1
          );
        },
        add: function add(className) {
          return _this.setAttribute(
            "class",
            _this.getAttribute("class") + " " + className
          );
        },
        remove: function remove(className) {
          var removedClass = _this
            .getAttribute("class")
            .replace(
              new RegExp(
                "(\\s|^)".concat(className, "(\\s|$)"),
                "g"
              ),
              "$2"
            );

          if (_this.classList.contains(className)) {
            _this.setAttribute("class", removedClass);
          }
        },
        toggle: function toggle(className) {
          if (this.contains(className)) {
            this.remove(className);
          } else {
            this.add(className);
          }
        },
      };
    },
  });
}

(function () {
  "use strict";

  RegionsMap.prototype._forEach = function (array, callback, scope) {
    for (var i = 0; i < array.length; i++) {
      callback.call(scope, i, array[i]); // passes back stuff we need
    }
  };

  RegionsMap.prototype._init = function () {
    var _this = this;

    if (window.innerWidth >= 640) {
      this.isMobile = false;
    } else {
      this.isMobile = true;
    }

    const regions = document.querySelectorAll("#bw .region");
    const regionExplorerContent = document.querySelector(
      ".regions-map-content"
    );
    const regionContents = document.querySelectorAll(
      ".regions-map-content .region-content"
    );

    for (let h = 0; h < regionContents.length; h++) {
      if (!regionContents[h].classList.contains("is-active")) {
        regionContents[h].classList.add("is-hidden");
      } else {
        regionContents[h].classList.remove("is-hidden");
      }
    }

    for (let i = 0; i < regions.length; i++) {
      let region = regions[i];

      region.addEventListener("click", function (e) {
        e.preventDefault();
        let regionname = e.currentTarget.getAttribute("data-region");
        let regionSelector = "[data-region='" + regionname + "']";
        let activeRegion =
          regionExplorerContent.querySelector(regionSelector);
        for (let k = 0; k < regionContents.length; k++) {
          regionContents[k].classList.add("is-hidden");
        }
        activeRegion.classList.remove("is-hidden");

        for (let j = 0; j < regions.length; j++) {
          regions[j].classList.remove("is-active");
        }
        region.classList.add("is-active");
      });
    }

    var resizeRegionsMap = this._debounce(function () {
      // This gets delayed for performance reasons
      /* Old stuff, check if needed
            _this.ratio = _this.mapBackgroundWidth / window.innerWidth;
            _this.mapOuter.setAttribute('style', ("--ratio:" + _this.ratio));

            if (window.innerWidth >= _this.options.breakpoint) {
                _this.isMobile = false;
                _this.controls.appendChild(
                    document.querySelector('.extension-map-detailinfo')
                );
            } else {
                _this.isMobile = true;
                _this.map.appendChild(
                    document.querySelector('.extension-map-detailinfo')
                );
            }*/
    }, 50);

    window.addEventListener("resize", resizeRegionsMap);
  };

  RegionsMap.prototype._initMap = function (_this) { };

  RegionsMap.prototype._switchMap = function (id) {
    let _this = this;
  };

  RegionsMap.prototype._addEventListeners = function (_this) {
    const mapSelector = document.getElementById("map-selection");
    mapSelector.addEventListener("change", (e) => {
      let selectedID = e.target.value;
      this._switchMap(selectedID);
    });
  };

  // Returns a function, that, as long as it continues to be invoked, will not
  // be triggered. The function will be called after it stops being called for
  // N milliseconds. If `immediate` is passed, trigger the function on the
  // leading edge, instead of the trailing.
  RegionsMap.prototype._debounce = function (func, wait, immediate) {
    var timeout;
    return function () {
      var context = this,
        args = arguments;
      var later = function () {
        timeout = null;
        if (!immediate) {
          func.apply(context, args);
        }
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) {
        func.apply(context, args);
      }
    };
  };

  var regionsMapContainer =
    document.getElementsByClassName("j-regions-map")[0];

  if (regionsMapContainer != null) {
    var mapTrainload = new RegionsMap();
  }

  function RegionsMap(options) {
    this.isMobile = true;

    this._init();
  }
})();
