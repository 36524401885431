/**
 *    Copyright 2022 Beaufort 8 GmbH

   Licensed under the Apache License, Version 2.0 (the "License");
   you may not use this file except in compliance with the License.
   You may obtain a copy of the License at

       http://www.apache.org/licenses/LICENSE-2.0

   Unless required by applicable law or agreed to in writing, software
   distributed under the License is distributed on an "AS IS" BASIS,
   WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
   See the License for the specific language governing permissions and
   limitations under the License.

 */

// Video player
// Dependency: Plyr / https://plyr.io
(function () {
  'use strict';

  const i18nDE = {
    restart: 'Neustart',
    rewind: '{seektime} Sekunden zurückspulen',
    play: 'Wiedergabe',
    pause: 'Pause',
    fastForward: '{seektime} Sekunden vorspulen',
    seek: 'Abspielposition',
    seekLabel: '{currentTime} von {duration}',
    played: 'Abgespielt',
    buffered: 'Gebuffered',
    currentTime: 'Aktuelle Zeit',
    duration: 'Dauer',
    volume: 'Lautstärke',
    mute: 'Stummschalten',
    unmute: 'Stummschaltung aufheben',
    enableCaptions: 'Untertitel einschalten',
    disableCaptions: 'Untertitel ausschalten',
    download: 'Herunterladen',
    enterFullscreen: 'Vollbild',
    exitFullscreen: 'Vollbild schließen',
    frameTitle: 'Player für {title}',
    captions: 'Untertiel',
    settings: 'Einstellungen',
    pip: 'PIP',
    menuBack: 'Zurück zum vorigen Menü',
    speed: 'Geschwindigkeit',
    normal: 'Normal',
    quality: 'Qualität',
    loop: 'Loop',
    start: 'Start',
    end: 'Ende',
    all: 'Alle',
    reset: 'Zurücksetzen',
    disabled: 'Deaktiviert',
    enabled: 'Aktiviert',
    advertisement: 'Anzeige',
    qualityBadge: {
      2160: '4K',
      1440: 'HD',
      1080: 'HD',
      720: 'HD',
      576: 'SD',
      480: 'SD',
    },
  };

  function initPlayers() {

    var fastConnection = false;

    var standardDef = "https://player.vimeo.com/external/238536997.sd.mp4?s=7da0217c251bbc251afd143f1f436706dc65992f&profile_id=164";
    var mediumDef = "https://player.vimeo.com/external/238536997.sd.mp4?s=7da0217c251bbc251afd143f1f436706dc65992f&profile_id=165";
    var highDef = "https://player.vimeo.com/external/238536997.hd.mp4?s=72d204afc30893cc8879e769b4acf3e253a67ec6&profile_id=174";
    var fullDef = "https://player.vimeo.com/external/238536997.hd.mp4?s=72d204afc30893cc8879e769b4acf3e253a67ec6&profile_id=175";
  
    var standardPoster = "https://bwegt.de/fileadmin/static/assets/img/img-home-hero-anim/img-home-hero-anim-640w.jpg";
    var mediumPoster = "https://bwegt.de/fileadmin/static/assets/img/img-home-hero-anim/img-home-hero-anim-960w.jpg";
    var highPoster = "https://bwegt.de/fileadmin/static/assets/img/img-home-hero-anim/img-home-hero-anim-1280w.jpg";
    var fullPoster = "https://bwegt.de/fileadmin/static/assets/img/img-home-hero-anim/img-home-hero-anim-1920w.jpg";
  
    var heroplayerElement = document.querySelectorAll('.js-player')[0];
  
    var iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
    var noiOS = !iOS;
  
    var plyrIconURL = "/fileadmin/static/assets/img/video-module/plyr.svg";
  
    if(window.location.href.indexOf("fractal") > -1 || window.location.href.indexOf("localhost") > -1) {
      console.log('local environment!');
      plyrIconURL = "/fileadmin/static/assets/img/video-module/plyr.svg";
    }
  
    var setVideoSource = function(player){
  
      var newSource = standardDef;
      var newPoster = standardPoster;
      
      if ((window.innerWidth * window.devicePixelRatio) > 640) {
        newSource = mediumDef;
        newPoster = mediumPoster;
      }
      if ((window.innerWidth * window.devicePixelRatio) > 960) {
        newSource = highDef;
        newPoster = highPoster;
      }
      if ((window.innerWidth * window.devicePixelRatio) > 1280) {
        newSource = fullDef;
        newPoster = fullPoster;
      }
  
      player.source = newSource;
      player.autoplay = noiOS;
      player.poster = newPoster;
  
    };
  
    if (heroplayerElement) {
      var heroplayer = new Plyr(heroplayerElement, { controls: ['play', 'play-large', 'mute', 'fullscreen' ], 'i18n': i18nDE });
      heroplayer.muted = false;
  
      if (heroplayerElement.classList.contains('bwegt-hero-video')) {
        setVideoSource(heroplayer);
      }
    }
  
    /**
     * Checks the connection speed by calculating the time it takes to download a resource
     * Uses the Resource Timing API: https://developer.mozilla.org/en-US/docs/Web/API/Resource_Timing_API
     */
    const checkConnectionSpeed = function(resource) {
      // Check performance support
      if (window.performance === undefined) {
        //console.log("! window.performance NOT supported");
        return;
      }
  
      
          // Get the performance entry for the poster image for the speed test
          let payload = window.performance.getEntriesByName(resource)[0];
  
          // Return if there is no performance record for the resource
          if (payload === undefined) {
            //console.log("! NO payload, quitting…");
            return;
          }
  
          // Download time: difference between response start and end times
          //let downloadTime = (payload.redirectStart > 0) ? (payload.responseEnd - payload.redirectStart) : "0";
          let downloadTime = payload.duration;
  
          // Benchmark: If 100kb take 2000ms to download, we're approx. at the slowest DSL speed 
          let allowedTime = payload.transferSize / 100000 * 2000; 
          let fromCache = (payload.decodedBodySize < 100) ? true : false;
  
          //console.log(payload);
          //console.log(allowedTime);
  
          if (downloadTime >= allowedTime && fromCache == false) {
            //console.log("This is a slow connection. No videos for you today…");
            fastConnection = false;
          } else {
            //console.log("Fast connection or no connection speed test possible! Load the video!");
            fastConnection = true;
          }
    }
  
  
  
  
  /**
   * Init the standard players
   */  

    const stdPlayers = Array.from(document.querySelectorAll('.js-standard-player')).map(function(p) {
        if(p.getAttribute('data-plyr-provider') == 'youtube') {
            if(p.parentNode.classList.contains("campaign-video")) {
                let parent = p.parentNode;
                let pl = new Plyr(p, { youtube: { noCookie: true, captions: {active: true, language: 'de'}, rel: 0, showinfo: 0, iv_load_policy: 3, modestbranding: 1, cc_lang_pref: 'de', cc_load_policy: 1 }, clickToPlay: true, 'iconUrl': plyrIconURL, 'i18n': i18nDE});
                pl.on('ready', (event) => {
                    const svgs = parent.querySelectorAll('svg[role="presentation"]');
                    for (let s = 0; s < svgs.length; s++) {
                        const svg = svgs[s];
                        svg.removeAttribute("role");
                        svg.setAttribute("aria-hidden", "true");
                    }
                });
                document.getElementsByClassName("plyr__video-wrapper")[0].addEventListener("click", event => {
                    pl.togglePlay();
                    event.stopPropagation();
                });
            } else {
                let pl = new Plyr(p, { youtube: { noCookie: true, captions: {active: true, language: 'de'}, rel: 0, showinfo: 0, iv_load_policy: 3, modestbranding: 1, cc_lang_pref: 'de', cc_load_policy: 1 }, clickToPlay: true, 'iconUrl': plyrIconURL, 'i18n': i18nDE});
                pl.on('ready', (event) => {
                    let data = event.detail.plyr.elements.container;
                    const svgs = data.querySelectorAll('svg[role="presentation"]');
                    for (let s = 0; s < svgs.length; s++) {
                        const svg = svgs[s];
                        svg.removeAttribute("role");
                        svg.setAttribute("aria-hidden", "true");
                    }
                });
                document.getElementsByClassName("plyr__video-wrapper")[0].addEventListener("click", event => {
                    pl.togglePlay();
                    event.stopPropagation();
                });
            } 
            
            /*pl.on('ready', event => {
                let element = document.querySelector('.main-video iframe')
                if(event.type == 'ready') {
                    //let element = event.target.childNodes[1].innerHTML;
                    element.style.visibility = 'hidden';
                } else {
                    element.style.visibility = 'visible';
                }
            });*/
        } else {
            let pl = new Plyr(p, { 'autopause': true, clickToPlay: true, 'iconUrl': plyrIconURL, 'i18n': i18nDE });
            if (p.classList.contains("plyr--youtube")) {
                pl.on("statechange", (event) => {
                //console.log("code: " + event.detail.code);
                var cPlyrControl = p.querySelector(".plyr__control--overlaid");
                var cPlyrPoster = p.querySelector(".plyr__poster");

                if (event.detail.code != -1) {
                    cPlyrPoster.style.pointerEvents = "none";
                }
                if (event.detail.code == 0) {
                    cPlyrControl.style.pointerEvents = "none";
                    cPlyrControl.style.opacity = "0";
                    cPlyrPoster.style.pointerEvents = "none";
                } 
                if (event.detail.code != 0) {
                    cPlyrControl.style.pointerEvents = "all";
                    cPlyrControl.style.opacity = null;
                }
                });
            }
            pl.on('ready', (event) => {
                const svgs = p.querySelectorAll('svg[role="presentation"]');
                for (let s = 0; s < svgs.length; s++) {
                const svg = svgs[s];
                svg.removeAttribute("role");
                svg.setAttribute("aria-hidden", "true");
                }
            });

			var videowrapperisset = document.getElementsByClassName("plyr__video-wrapper")[0];
			if (videowrapperisset){
				videowrapperisset.addEventListener("click", event => {
					pl.togglePlay();
					event.stopPropagation();
				});
			}
        };
    });
  
    /**
     * Init the hero and prefooter teaser players
     * Uses the IntersectionObserver API to onyl play the video if it's in the viewport:
     * https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
     */
    const heroPlayers = Array.from(document.querySelectorAll('.js-hero-video-player')).map(function(p) {
        let pl = new Plyr(p, { controls: [ ], 'muted': true, 'iconUrl': plyrIconURL });

        window.addEventListener('load', (event) => {

            checkConnectionSpeed(pl.poster);

            let intersectionObserver = new IntersectionObserver(function(entries) {

            // If intersectionRatio is 0, the target is out of view
            // and we do not need to do anything.
            if (entries[0].intersectionRatio <= 0) {
                pl.pause();
                return;
            }
            // Important! Firefox only plays video on intersection if it is muted
            if(fastConnection){
                pl.muted = true;
                pl.play();
            }
            });
            // start observing
            setTimeout(() => {
                if(typeof UC_UI == 'undefined') { return; }
                console.log('plyr => ' + UC_UI.getServicesBaseInfo().find(data => (data.id === "sZAqOmbXv")).consent.status);
                if (UC_UI.getServicesBaseInfo().find(data => (data.id === "sZAqOmbXv")).consent.status === true) {
                    intersectionObserver.observe(p);
                    }
            }, 2000);

        });

    });
       
  }

  if (document.querySelectorAll('.js-standard-player').length || document.querySelectorAll('.js-hero-video-player').length) {
      const playerScriptURL = "VENDORJSPATH/plyr.polyfilled.min.js";
      window.loadScript(playerScriptURL, initPlayers);
  }

})();
