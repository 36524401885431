/**
 *    Copyright 2022 Beaufort 8 GmbH

   Licensed under the Apache License, Version 2.0 (the "License");
   you may not use this file except in compliance with the License.
   You may obtain a copy of the License at

       http://www.apache.org/licenses/LICENSE-2.0

   Unless required by applicable law or agreed to in writing, software
   distributed under the License is distributed on an "AS IS" BASIS,
   WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
   See the License for the specific language governing permissions and
   limitations under the License.

 */

// Adapts the frame size for the iFrame "fahrplanauskunft"

(function () {
    'use strict';

    var iFrame = document.getElementById("fahrplanauskunft");

    var smallSnippet = "mobile3";
    var largeSnippet = "nvbw3L";

    var cid = "fahrplanauskunft";

    // Returns a function, that, as long as it continues to be invoked, will not
    // be triggered. The function will be called after it stops being called for
    // N milliseconds. If `immediate` is passed, trigger the function on the
    // leading edge, instead of the trailing.
    function debounce(func, wait, immediate) {
        var timeout;

        return function() {
            var context = this, 
                args = arguments;
            var later = function() {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };
            var callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait || 200);
            if (callNow) func.apply(context, args);
        };
    }

    // sets the iFrame source

    function setIFrameSource(cid, url){
      var ifr = document.getElementById(cid);
      if(ifr !== null){
        if(ifr.src){
          ifr.src = url; }
        else if(ifr.contentWindow !== null && ifr.contentWindow.location !== null){
          ifr.contentWindow.location = url; }
        else{ 
          ifr.setAttribute('src', url); 
        }
      }
    }

    // replaces the iFrame URL based on the current viewport width

    function checkViewport(){
        var currViewWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

        if (currViewWidth > 768) {
            if(iFrameUrl.indexOf(smallSnippet) > -1){
                var re = /mobile3/gi;
                var newUrl = iFrameUrl;
                newUrl = iFrameUrl.replace(re, largeSnippet);
                iFrameUrl = newUrl;
                setIFrameSource(cid, newUrl);
            }
        } else {
            if(iFrameUrl.indexOf(largeSnippet) > -1){
                var re2 = /nvbw3L/gi;
                var newUrl2 = iFrameUrl;
                newUrl2 = iFrameUrl.replace(re2, smallSnippet);
                iFrameUrl = newUrl2;
                setIFrameSource(cid, newUrl2);
            }
        }
    }

    if(iFrame) {
        var iFrameUrl = iFrame.src; 
        checkViewport();
        window.addEventListener('resize', debounce(checkViewport, 250));
    }

})();
