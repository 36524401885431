/**
 *    Copyright 2022 Beaufort 8 GmbH

   Licensed under the Apache License, Version 2.0 (the "License");
   you may not use this file except in compliance with the License.
   You may obtain a copy of the License at

       http://www.apache.org/licenses/LICENSE-2.0

   Unless required by applicable law or agreed to in writing, software
   distributed under the License is distributed on an "AS IS" BASIS,
   WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
   See the License for the specific language governing permissions and
   limitations under the License.

 */
(function() {
    "use strict";

    /**
     * Variables.
     */
    const filterContainer = document.querySelector(
        '[data-role="js-filter-dropdown"]'
    );

    let selectedArr = {
        "event-categories": {
            name: [],
            numberSelectedInputs: [],
        },
        "employer-type": {
            name: [],
            numberSelectedInputs: [],
        },
        "job-location": {
            name: [],
            numberSelectedInputs: [],
        },
        "job-employer": {
            name: [],
            numberSelectedInputs: [],
        }
    };

	let toubizPoiFormId = 'toubiz-poi-form';
	let toubizEventFormId = 'toubiz-event-form';

	let countTypePois = 'toubizPoiCount';
	let countTypeEvents = 'toubizEventCount';
	

	let eventSearchTextbox = $("#event-search");
	let eventLocationTextbox = $("#event-location");
	let eventLocationRadiusSelect = $("#event-location-radius");
	let bwegtPlusCheckbox = $('input[name="bwegt-plus"]').first();
	let eventCategoriesInput = $('#event-categories');

	let eventSearchDateStart = $("#search-date-start");
	let eventSearchDateEnd = $("#search-date-end");


	let lastEventSearchDateStart = eventSearchDateStart.val();
	let lastEventSearchDateEnd = eventSearchDateEnd.val();

	let lastEventSearch = eventSearchTextbox.val();
	let lastEventLocation = eventLocationTextbox.val();
	
	let lastCountSelectedCategoriesBeforeOpen = selectedArr['event-categories'].name.length;
	let lastCountSelectedCategoriesAfterClose = selectedArr['event-categories'].name.length;


    /**
     * Inits the form interactions.
     */

    const initForm = () => {
        if (filterContainer == null) {
            return;
        }

        inputListener();
        openFilters();
        clearFilters();
        searchInDropdown();
    };


    /**
     * Get filter selected value
     */
    const inputListener = () => {
        $('[data-role="dropdown-options"] label').on(
            "click",
            "input",
            (event) => {
                const el = $(event.target);
                checkOrUncheck(el, "click", false);
                countSelected(el, "click");
            }
        );

		$(window).on("load", function() {
			$('[data-role="dropdown"]').each(function() {
				const inputs = $(this)
					.children('[data-role="dropdown-options"]')
					.find("li:not(.dropbox__search):not(.dropbox__clear)");
				$(inputs).each(function() {
					const el = $(this).find("input");
					checkOrUncheck(el, "loop", false);
					countSelected(el, "loop");
				});
			});
			lastCountSelectedCategoriesBeforeOpen = selectedArr['event-categories'].name.length;
		});

		eventSearchTextbox.focusout(function(e){ 
			let countType = '';
			let formId = '';

			if (
				$(this).closest('form').attr('id') === toubizPoiFormId
			) {
				countType = countTypePois;
				formId = toubizPoiFormId;
			}

			if (
				$(this).closest('form').attr('id') === toubizEventFormId
			) {
				countType = countTypeEvents;
				formId = toubizEventFormId;
			}

			if (countType != '' && formId != '') {
				if (
					lastEventSearch !== e.target.value
				) {
					getInputBasedCounts(countType, formId);
				}
				lastEventSearch = e.target.value;
			}
			
		});

		eventLocationTextbox.focusout(function(e){ 
			let countType = '';
			let formId = '';

			if (
				$(this).closest('form').attr('id') === toubizPoiFormId
			) {
				countType = countTypePois;
				formId = toubizPoiFormId;
			}

			if (
				$(this).closest('form').attr('id') === toubizEventFormId
			) {
				countType = countTypeEvents;
				formId = toubizEventFormId;
			}

			if (countType != '' && formId != '') {
				if (
					lastEventLocation !== e.target.value
				) {
					// timeout because of autocomplete
					// user might click on a suggestion before finished writing full location name
					// so half a second is enough for the suggestion to populate the textbox
					// after the user clicks the suggestion
					setTimeout(function(){
						getInputBasedCounts(countType, formId);
					}, 500);
				}
				lastEventLocation = e.target.value;
			}
			
		});


		bwegtPlusCheckbox.on('change', function(e){
			let countType = '';
			let formId = '';

			if (
				$(this).closest('form').attr('id') === toubizPoiFormId
			) {
				countType = countTypePois;
				formId = toubizPoiFormId;
			}

			if (
				$(this).closest('form').attr('id') === toubizEventFormId
			) {
				countType = countTypeEvents;
				formId = toubizEventFormId;
			}

			if (countType != '' && formId != '') {
				getInputBasedCounts(countType, formId);
			}
		});

		eventLocationRadiusSelect.on('change', function(e){
			let currLocation = eventLocationTextbox.val();

			if (currLocation.trim().length > 0) {
				let countType = '';
				let formId = '';
	
				if (
					$(this).closest('form').attr('id') === toubizPoiFormId
				) {
					countType = countTypePois;
					formId = toubizPoiFormId;
				}
	
				if (
					$(this).closest('form').attr('id') === toubizEventFormId
				) {
					countType = countTypeEvents;
					formId = toubizEventFormId;
				}
	
				if (countType != '' && formId != '') {
					getInputBasedCounts(countType, formId);
				}
			}
			
		});

		eventSearchDateStart.on('change', function(e){
			let countType = '';
			let formId = '';

			if (
				$(this).closest('form').attr('id') === toubizPoiFormId
			) {
				countType = countTypePois;
				formId = toubizPoiFormId;
			}

			if (
				$(this).closest('form').attr('id') === toubizEventFormId
			) {
				countType = countTypeEvents;
				formId = toubizEventFormId;
			}

			if (countType != '' && formId != '') {

				if (lastEventSearchDateStart != $(this).val()) {
					getInputBasedCounts(countType, formId);
				}
				
			}
			
			lastEventSearchDateStart = $(this).val();
		});
		

		eventSearchDateEnd.on('change', function(e){ 
			let countType = '';
			let formId = '';

			if (
				$(this).closest('form').attr('id') === toubizPoiFormId
			) {
				countType = countTypePois;
				formId = toubizPoiFormId;
			}

			if (
				$(this).closest('form').attr('id') === toubizEventFormId
			) {
				countType = countTypeEvents;
				formId = toubizEventFormId;
			}

			if (countType != '' && formId != '') {

				if (lastEventSearchDateEnd != $(this).val()) {
					getInputBasedCounts(countType, formId);
				}
				
			}
			
			lastEventSearchDateEnd = $(this).val();
		});

		

		let currForm = eventSearchTextbox.closest('form').attr('id');

		if (currForm === 'toubiz-event-form') {
			let form = document.querySelector('.form-toubiz--event');
			let buttonToday  = form.querySelector('.js-button-today');
			let buttonTomorrow   = form.querySelector('.js-button-tomorrow');
			let buttonWeekend   = form.querySelector('.js-button-weekend');
			let timeButtons = [buttonToday, buttonTomorrow, buttonWeekend];
	
			timeButtons.forEach(button => {
					
				var quickFunc = function($this){
					var countType = '';
					var formId = '';

					if (
						$this.closest('form').attr('id') === toubizPoiFormId
					) {
						countType = countTypePois;
						formId = toubizPoiFormId;
					}
	
					if (
						$this.closest('form').attr('id') === toubizEventFormId
					) {
						countType = countTypeEvents;
						formId = toubizEventFormId;
					}
					
	
					
					if (countType != '' && formId != '') {
	
						if (
							
							(
								(lastEventSearchDateStart != eventSearchDateStart.val())
								||
								(lastEventSearchDateEnd != eventSearchDateEnd.val())
							)
							||
							(
								lastEventSearchDateStart.length == 0 && eventSearchDateStart.val().length > 0
								||
								lastEventSearchDateEnd.length == 0 && eventSearchDateEnd.val().length > 0
							)
						) {
							
							getInputBasedCounts(countType, formId);
						}
						
					}
					
					lastEventSearchDateStart = eventSearchDateStart.val();
					lastEventSearchDateEnd = eventSearchDateEnd.val();
				};
	
				
				button.addEventListener('click', (e) => {
					
					var $this = $(e.target);
	
					setTimeout(
						function(){
							quickFunc($this);
						},
						500
					);
				});
			});
		}
		

    };

	

    /**
     * checkOrUncheck checkbox
     */

    const checkOrUncheck = (element, event, clearVisibilityClasses = true) => {
			let nameAttr = element.attr('name');
			let children = element.closest('li').find('li input.form-check-input');
					let inputID = element
				.closest('[data-role="dropdown-options"]')
				.prev()
				.children("input")
				.attr("id");
			if(event != 'loop') {
				const elementChecked = ($('[data-role="dropdown"]')
					.children('[data-role="dropdown-options"]')
					.find("li:not(.dropbox__search):not(.dropbox__clear) input:checkbox:checked"));

				if (elementChecked.length == 0) {
					selectedArr[`${inputID}`]["name"] = [];
				}
			}

			if (element.is(':checked')) {
				if (clearVisibilityClasses) {
					clearFilterSearchInput(element);
				}
				
				element
					.closest('[data-role="dropdown-options"]')
					.prev()
					.children("input")
					.val(element.attr("data-name"));
				element
					.closest('[data-role="dropdown-options"]')
					.prev()
					.children("input")
					.addClass("selected");

				if (event != "loop") {
					selectedArr[`${inputID}`]["name"].push(element.attr("data-name"));
					if (element.closest('li').attr('data-role') == 'level-0' || element.closest('li').attr('data-role') == 'level-1' || element.closest('li').children('ul') != null) {
						if (children != null) {
							$(children).each(function(i, e) {
								$(e).prop('checked', false);
								$(e).attr('data-checked', true);
								$(e).removeAttr('name');
							})
						}
					}
				}
				
			} else {
				element.prop("checked", false);
				if (event != "loop") {
					if (element.closest('li').attr('data-role') == 'level-0') {
						if (children != null) {
							$(children).each(function(i, e) {
								$(e).prop('checked', false);
								$(e).attr('data-checked', false);
								if(nameAttr != undefined) {
									$(e).attr('name', nameAttr);
								}
							})
						}
					}
		
					if (element.closest('li').attr('data-role') == 'level-1' || element.closest('li').children('ul') != null) {
						if (element.parents('.level-0').find('input.form-check-input').prop('checked') != true) {
							if (children != null) {
								$(children).each(function(i, e) {
									$(e).prop('checked', false);
									$(e).attr('data-checked', false);
									if (nameAttr != undefined) {
										$(e).attr('name', nameAttr);
									}
								})
							}
						}
					}
					if (clearVisibilityClasses) {
						clearFilterSearchInput(element);
					}
					let index = selectedArr[`${inputID}`]["name"].indexOf(
						element.attr("data-name")
					);
					selectedArr[`${inputID}`]["name"].splice(index, 1);
				}
			}

			if (event == "loop") {
				if(element.prop('checked')) {
					selectedArr[`${inputID}`]["name"].push(element.attr("data-name"));
					if(element.closest('li').attr('data-role') == 'level-0' || element.closest('li').attr('data-role') == 'level-1') {
						if(children != null) {
							$(children).each(function(i, e) {
								$(e).prop('checked', false);
								$(e).attr('data-checked', true);
								$(e).removeAttr('name');
							})
						}
					}
				}
			}
    };

	/**
	 * count number of selected checkboxes and add on main input
	 */

	const countSelected = (element, event) => {
		let inputID = element
			.closest('[data-role="dropdown-options"]')
			.prev()
			.children("input")
			.attr("id");
		if(Object.keys(selectedArr[`${inputID}`]["name"]).length >= 25) {
			element.parent('ul').find('li .form-check-input:not(:checked)').each(function(i, e) {
				$(e).prop('disabled', true)
			});
			element.parent('.dropbox').prev().addClass('error-show');
		} else {
			element.parent('ul').find('li .form-check-input:not(:checked)').each(function(i, e) {
				$(e).prop('disabled', false)
			})
			element.parent('.dropbox').prev().removeClass('error-show');
		}
		if (Object.keys(selectedArr[`${inputID}`]["name"]).length != 0) {
			element
				.closest('[data-role="dropdown-options"]')
				.find('.dropbox__clear')
				.attr('aria-hidden','false');
			element
				.closest('[data-role="dropdown-options"]')
				.addClass("has-selection");
			if (Object.keys(selectedArr[`${inputID}`]["name"]).length > 1) {
				selectedArr[`${inputID}`]["numberSelectedInputs"] = ` (+${
			Object.keys(selectedArr[`${inputID}`]["name"]).length - 1})`;
			} else {
				selectedArr[`${inputID}`]["numberSelectedInputs"] = [];
			}
			element
				.closest('[data-role="dropdown-options"]')
				.prev()
				.children("input")
				.val(
					selectedArr[`${inputID}`]["name"].slice(-1).pop() +
						`${selectedArr[`${inputID}`]["numberSelectedInputs"]}`
				);
		} else {
			element
				.closest('[data-role="dropdown-options"]')
				.find('.dropbox__clear')
				.attr('aria-hidden','false');
			element
				.closest('[data-role="dropdown-options"]')
				.removeClass("has-selection");
			if (event != "loop") {
				element
					.closest('[data-role="dropdown-options"]')
					.prev()
					.children("input")
					.val("");
				element
					.closest('[data-role="dropdown-options"]')
					.prev()
					.children("input")
					.removeClass("selected");
			}
		}
	}

    /**
	 * Open/Close filters by clicking on input
	 */

	const openFilters = () => {
		$('[data-role="input"]').on("click", (event) => {
			const el = $(event.target);

			let countType = '';
			let formId = '';

			if (
				el.closest('form').attr('id') === toubizPoiFormId
			) {
				countType = countTypePois;
				formId = toubizPoiFormId;
			}

			if (
				el.closest('form').attr('id') === toubizEventFormId
			) {
				countType = countTypeEvents;
				formId = toubizEventFormId;
			}
			
			let formEl = $('#' + formId);
			let loader = formEl.find('.form-update-loader')

			if (loader.hasClass('_show')) {
				return false;
			}

			el.parent().siblings().toggleClass("_open");
			el.parent().toggleClass("_open");
			$('[data-role="input"]').each(function () {
				if (el.attr("id") != $(this).attr("id")) {
					$(this).parent().siblings().removeClass("_open");
					$(this).parent().removeClass("_open");
				}
			});


			if(el.parent().hasClass("_open")) {
				lastCountSelectedCategoriesBeforeOpen = selectedArr['event-categories'].name.length;
			}
			if(!el.parent().hasClass("_open")) {
				lastCountSelectedCategoriesAfterClose = selectedArr['event-categories'].name.length;

				if(lastCountSelectedCategoriesBeforeOpen != lastCountSelectedCategoriesAfterClose) {
					
	
					if (countType != '' && formId != '') {
						getInputBasedCounts(countType, formId);
					}
				}

			}

		});

		$("body").click(function (event) {

			if (!$(event.target).closest('[data-role="dropdown"]').length) {
				
				$('[data-role="dropdown-options"]').removeClass("_open");
				$('[data-role="dropdown-wrapper"]').removeClass("_open");

				lastCountSelectedCategoriesAfterClose = selectedArr['event-categories'].name.length;
				
				if(lastCountSelectedCategoriesBeforeOpen != lastCountSelectedCategoriesAfterClose) {
					let countType = '';
					let formId = '';
	
					if (
						$('#toubiz-poi-form').length === 1
					) {
						countType = countTypePois;
						formId = toubizPoiFormId;
					}
	
					if (
						$('#toubiz-event-form').length === 1
					) {
						countType = countTypeEvents;
						formId = toubizEventFormId;
					}
	
					if (countType != '' && formId != '') {
						getInputBasedCounts(countType, formId);
					}
				}

			}
		});
	};

    /**
	 * Resets all filters and inputs
	 */
	const resetAllFilters = () => {
		clearAllFilters();
	};

    const clearFilterSearchInput = (element) => {
		let targetRemove = element
			.closest('[data-role="dropdown-options"]')
			.find("li:not(.dropbox__search):not(.dropbox__clear)");
		// clear dropdown input
		element
			.closest('[data-role="dropdown-options"]')
			.find('[data-role="dropdown__search"]')
			.val("");

		$(targetRemove).each(function () {
			$(this).removeClass("_hide");
			$(this).removeClass("_show");
		});
	};

    /**
	 * Clear filter checkboxes by clicking on button
	 */

	const clearFilters = () => {
		$('[data-action="dropdown-clear"]').on("click", (event) => {
			event.preventDefault();
			const target = $(event.target);
			let filterOptions = target
				.closest('[data-role="dropdown-options"]')
				.find("li:not(.dropbox__search):not(.dropbox__clear)");
			target
				.parent()
				.attr('aria-hidden','true');
			target
				.closest('[data-role="dropdown-options"]')
				.removeClass("has-selection");
			$(filterOptions).each(function () {
				let el = $(this).find("input");
				let inputID = el
					.closest('[data-role="dropdown-options"]')
					.prev()
					.children("input")
					.attr("id");
				el.prop("checked", false);
				$(el).attr('data-checked', false);
				el.closest('[data-role="dropdown-options"]')
					.prev()
					.children("input")
					.val("");
				el.closest('[data-role="dropdown-options"]')
					.prev()
					.children("input")
					.removeClass("selected");
				selectedArr[`${inputID}`]["name"] = [];
			});
		});
	};

    const clearAllFilters = () => {
		let filters = document.querySelectorAll('[data-role="dropdown-options"]');

		for (let f = 0; f < filters.length; f++) {
			const filter = filters[f];
			filter.querySelector('.dropbox__clear').setAttribute('aria-hidden', 'false')
			filter.classList.remove("has-selection");
			let filterOptions = filter.querySelectorAll(
				"li:not(.dropbox__search):not(.dropbox__clear)"
			);
			for (let o = 0; o < filterOptions.length; o++) {
				const optionEl = filterOptions[o];
				let el = optionEl.querySelectorAll("input")[0];
				let inputID = $(el)
					.closest('[data-role="dropdown-options"]')
					.prev()
					.children("input")
					.attr("id");
				el.checked = false;
				$(el).removeAttr("checked");
				$(el)
					.closest('[data-role="dropdown-options"]')
					.prev()
					.children("input")
					.val("");
				$(el)
					.closest('[data-role="dropdown-options"]')
					.prev()
					.children("input")
					.removeClass("selected");
				selectedArr[`${inputID}`]["name"] = [];
			}
		}
	};

    /**
	 * if dropdown has more than 8 checkboxes enable users of searching on main input
	 */

	const searchInDropdown = () => {
		$('[data-role="dropdown-search"]').on("keyup", (event) => {
			const target = $(event.target);
			const dataToMatch = target
				.closest('[data-role="dropdown-options"]')
				.find("li:not(.dropbox__search):not(.dropbox__clear)");
			$(dataToMatch).each(function () {
				let apiId = $(this).find("input").attr("value");
				let count = $('#counter_' + apiId).attr('data-count');
				if (
					count == 0
				) {
					$(this).removeClass("_show");
					$(this).addClass("_hide");
					return;
				}

				if (
					$(this)
						.find("input")
						.attr("data-name")
						.toUpperCase()
						.indexOf(target.val().toUpperCase()) != -1
				) {
					$(this).addClass("_show");
					if($(this).hasClass('level-1')) {
						$(this).closest('li.level-0').removeClass('_hide')
					}

					if($(this).hasClass('level-2')) {
						$(this).closest('li.level-1').removeClass('_hide')
						$(this).closest('li.level-0').removeClass('_hide')
					}
					$(this).removeClass("_hide");
				} else {
					$(this).addClass("_hide");
					$(this).removeClass("_show");
				}
			});
		});
	};


	const getInputBasedCounts = (eID, formId) => {
		let baseUrl =
				location.protocol +
				"//" +
				window.location.hostname +
				"/index.php?";
			let urlQuery =
				"eID="+eID+"&" + getAllFormInput(formId);

			let formEl = $('#' + formId);
			let submitButton = formEl.find('button[type="submit"]').first();
			
			let loader = formEl.find('.form-update-loader')
			if (loader.length > 0) {
				loader.addClass('_show');
			}
			

			window
				.fetch(baseUrl + urlQuery)
				.then(function (response) {
					return response.json();
				})
				.then(function (data) {


					let categoriesInputDropdown = eventCategoriesInput.closest('[data-role="dropdown"]');

					// update counts by category
					// hide categories with count 0
					$.each(data.total_by_category, function( index, value ) {
						let counterEl = $('#counter_' + index);
						counterEl.text('(' + value + ')');
						counterEl.attr('data-count', value);

						
						if (value == 0) {
							categoriesInputDropdown.find('input.form-check-input[value="'+index+'"]').closest('li').addClass('_hide');
						} else {
							categoriesInputDropdown.find('input.form-check-input[value="'+index+'"]').closest('li').removeClass('_hide');
						}
					});

					// disabling the bwegtPlusOnly checkbox
					// might not be a good thing.
					// let the user play with it.
					/*
					if (data.total_bwegt_plus_only == 0) {
						bwegtPlusCheckbox.attr('disabled','disabled'); 
						bwegtPlusCheckbox.closest('.nur-bwegt-plus-checkbox').addClass('disabled');
					} else {
						bwegtPlusCheckbox.removeAttr('disabled');
						bwegtPlusCheckbox.closest('.nur-bwegt-plus-checkbox').removeClass('disabled');
					}*/

					
					if (data.total == 0) {
						submitButton.attr('disabled', 'disabled');
						submitButton.find('.submit-btn-text').text('Keine Treffer gefunden');
						$('#event-categories').attr('disabled', 'disabled');
					} else {
						submitButton.removeAttr('disabled');
						$('#event-categories').removeAttr('disabled');
						submitButton.find('.submit-btn-text').text('Treffer anzeigen (' + data.total + ')');
					}

					lastCountSelectedCategoriesBeforeOpen = lastCountSelectedCategoriesAfterClose;

					if (loader.length > 0) {
						loader.removeClass('_show');
					}

				})
				.catch((error) => {
					if (loader.length > 0) {
						loader.removeClass('_show');
					}
				});
	}
    
	const getAllFormInput = (formId) => {
		let form = $('#' + formId); 
		let queryString = '';

		if (form.length > 0) {
			queryString = form.serialize();
		}

		return decodeURIComponent(queryString);
	}

    initForm();
})();
