/**
 *    Copyright 2022 Beaufort 8 GmbH

   Licensed under the Apache License, Version 2.0 (the "License");
   you may not use this file except in compliance with the License.
   You may obtain a copy of the License at

       http://www.apache.org/licenses/LICENSE-2.0

   Unless required by applicable law or agreed to in writing, software
   distributed under the License is distributed on an "AS IS" BASIS,
   WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
   See the License for the specific language governing permissions and
   limitations under the License.

 */
/*! Search form */
(function (global) {
  'use strict';

  // There are many ways to pick a DOM node; here we get the form itself and the email
  // input box, as well as the span element into which we will place the error message.

  var form  = document.querySelector('#contact-form');

  var email = document.querySelector('#contact-form-email');
  var emailError = document.querySelector('.input-group--email .error');
  var message = document.querySelector('#contact-form-message');
  var messageError = document.querySelector('.input-group--message .error');
  var consent = document.querySelector('#contact-form-consent');
  var consentError = document.querySelector('.input-group--consent .error');

  if(email){    

    email.addEventListener("input", function (event) {
      // Each time the user types something, we check if the
      // email field is valid.
      if (email.validity.valid) {
        // In case there is an error message visible, if the field
        // is valid, we remove the error message.
        emailError.innerHTML = ""; // Reset the content of the message
        emailError.className = "error"; // Reset the visual state of the message
        if(email.classList.contains('has-error')){
          email.classList.add('has-success');
        }
        email.classList.remove('has-error');
      } else {
        email.classList.add('has-error');
        email.classList.remove('has-success');
      }
    }, false);

    message.addEventListener("input", function (event) {
      // Each time the user types something, we check if the
      // email field is valid.
      if (message.validity.valid) {
        messageError.innerHTML = ""; // Reset the content of the message
        messageError.className = "error"; // Reset the visual state of the message
        if(message.classList.contains('has-error')){
          message.classList.add('has-success');
        }
        message.classList.remove('has-error');
      }
    }, false);

    consent.addEventListener("change", function (event) {
      // Each time the user types something, we check if the
      // email field is valid.
      if (consent.validity.valid) {
        consentError.innerHTML = ""; // Reset the content of the consent
        consentError.className = "error"; // Reset the visual state of the consent
        if(consent.classList.contains('has-error')){
          consent.classList.add('has-success');
        }
        consent.classList.remove('has-error');
      } else {
        consent.classList.add('has-error');
      }
    }, false);

  }

  if(form){
    form.addEventListener("submit", function (event) {
      // Each time the user tries to send the data, we check
      // if the email field is valid.
      if (!email.validity.valid) {
        
        // If the field is not valid, we display a custom
        // error message.
        emailError.innerHTML = "I expect an e-mail, darling!";
        emailError.className = "error has-error";
        email.classList.add('has-error');
        // And we prevent the form from being sent by canceling the event
        event.preventDefault();
      }

      if (!message.validity.valid) {
        messageError.innerHTML = "I expect a message, darling!";
        messageError.className = "error has-error";
        message.classList.add('has-error');
        // And we prevent the form from being sent by canceling the event
        event.preventDefault();
      }

      if (!consent.validity.valid) {
        consentError.innerHTML = "I expect consent, darling!";
        consentError.className = "error has-error";
        consent.classList.add('has-error');
        // And we prevent the form from being sent by canceling the event
        event.preventDefault();
      }

    }, false);
  }
  

})();
