/**
 *    Copyright 2022 Beaufort 8 GmbH

   Licensed under the Apache License, Version 2.0 (the "License");
   you may not use this file except in compliance with the License.
   You may obtain a copy of the License at

       http://www.apache.org/licenses/LICENSE-2.0

   Unless required by applicable law or agreed to in writing, software
   distributed under the License is distributed on an "AS IS" BASIS,
   WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
   See the License for the specific language governing permissions and
   limitations under the License.

 */
(function () {
	"use strict";

	// Make the whole card clickable
	let compactTeasers = document.querySelectorAll(
		".slider-mobile .grid-container .wrapper .teaser-article"
	);
	for (let ct = 0; ct < compactTeasers.length; ct++) {
		const teaser = compactTeasers[ct];
		// https://jira.beaufort8.de:8443/browse/BES14217-304?focusedCommentId=45252&page=com.atlassian.jira.plugin.system.issuetabpanels%3Acomment-tabpanel#comment-45252
		if (
			teaser.getAttribute("data-nojslink") !== null &&
			teaser.getAttribute("data-nojslink") !== "1"
		) {
			teaser.addEventListener("click", (e) => {
				if (e.currentTarget.querySelector("a") !== null) {
					let href = e.currentTarget
						.querySelector("a")
						.getAttribute("href");
					window.location.href = href;
				}
			});
		}
	}

	var teaserSliderElements = document.querySelectorAll(
		".slider-mobile .grid-container .wrapper"
	);
	var teaserSliders = [];
	var breakpoint = 480;

	var isMobile = false;

	var initTeaserSliders = function () {
		teaserSliderElements = document.querySelectorAll(
			".slider-mobile .grid-container .wrapper"
		);

		for (let s = 0; s < teaserSliderElements.length; s++) {
			const teaser = teaserSliderElements[s];
			//const container = teaser.querySelector('.grid-container');

			let teaserSlider = tns({
				container: teaser,
				items: 1,
				lazyload: true,
				//controlsContainer: '.gallery-slider-prevnext',
				//navContainer: '.gallery-slider-controls',
				controls: false,
				autoplay: false,
				speed: 400,
				slideBy: "page",
				touch: true,
				mouseDrag: true,
				arrowKeys: false,
				gutter: 16,
				navPosition: "bottom",

				onInit: function (info) {
					for (let i = 0; i < info.navItems.length; i++) {
						info.navItems[i].setAttribute("tabindex", 0);
					}
				},
			});

			var removeTabIndex = function (info) {
				// direct access to info object
				for (let i = 0; i < info.navItems.length; i++) {
					info.navItems[i].setAttribute("tabindex", 0);
				}
			};

			// bind function to event
			teaserSlider.events.on("indexChanged", removeTabIndex);

			teaserSliders.push(teaserSlider);
		}
	};

	var destroyTeaserSliders = function () {
		if (teaserSliders.length > 0) {
			for (let ts = 0; ts < teaserSliders.length; ts++) {
				const teaserslider = teaserSliders[ts];

				teaserslider.destroy();
			}

			teaserSliders = [];
		}
	};

	var resizeTeaserGrid = function () {
		// This gets delayed for performance reasons

		if (window.innerWidth >= breakpoint) {
			if (isMobile) {
				isMobile = false;
				destroyTeaserSliders();
			}
		} else {
			if (isMobile == false) {
				isMobile = true;
				initTeaserSliders();
			}
		}
	};

	if (teaserSliderElements) {
		if (window.innerWidth >= breakpoint) {
			isMobile = false;
		} else {
			isMobile = true;
			initTeaserSliders();
		}

		window.addEventListener("resize", function () {
			requestAnimationFrame(resizeTeaserGrid);
		});
	}

	// Show more button

	$(".show-articles-btn").click(function (event) {
		$(this).siblings(".grid-container").removeClass("hidden-articles");
		var y = $(this)
			.siblings(".grid-container")
			.find(".teaser:eq(6)")
			.offset(); //get offset of 6th element (first hidden element)
		$(this).remove();

		$("html, body").animate(
			{
				scrollTop: y.top - 100, //scroll smoothly to 6th element
			},
			500
		);
	});

	// Remove show more button on "desktop"
	if (window.innerWidth >= 800) {
		$(".show-articles-btn")
			.siblings(".grid-container")
			.removeClass("hidden-articles");
		$(".show-articles-btn").remove();
	}
})();
