/**
 *    Copyright 2022 Beaufort 8 GmbH

   Licensed under the Apache License, Version 2.0 (the "License");
   you may not use this file except in compliance with the License.
   You may obtain a copy of the License at

       http://www.apache.org/licenses/LICENSE-2.0

   Unless required by applicable law or agreed to in writing, software
   distributed under the License is distributed on an "AS IS" BASIS,
   WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
   See the License for the specific language governing permissions and
   limitations under the License.

 */
(function () {
  'use strict';

  // Production steps of ECMA-262, Edition 5, 15.4.4.18
  // Reference: http://es5.github.io/#x15.4.4.18
  if (!Array.prototype.forEach) {

    Array.prototype.forEach = function(callback/*, thisArg*/) {

      var T, k;

      if (this == null) {
        throw new TypeError('this is null or not defined');
      }

      // 1. Let O be the result of calling toObject() passing the
      // |this| value as the argument.
      var O = Object(this);

      // 2. Let lenValue be the result of calling the Get() internal
      // method of O with the argument "length".
      // 3. Let len be toUint32(lenValue).
      var len = O.length >>> 0;

      // 4. If isCallable(callback) is false, throw a TypeError exception. 
      // See: http://es5.github.com/#x9.11
      if (typeof callback !== 'function') {
        throw new TypeError(callback + ' is not a function');
      }

      // 5. If thisArg was supplied, let T be thisArg; else let
      // T be undefined.
      if (arguments.length > 1) {
        T = arguments[1];
      }

      // 6. Let k be 0.
      k = 0;

      // 7. Repeat while k < len.
      while (k < len) {

        var kValue;

        // a. Let Pk be ToString(k).
        //    This is implicit for LHS operands of the in operator.
        // b. Let kPresent be the result of calling the HasProperty
        //    internal method of O with argument Pk.
        //    This step can be combined with c.
        // c. If kPresent is true, then
        if (k in O) {

          // i. Let kValue be the result of calling the Get internal
          // method of O with argument Pk.
          kValue = O[k];

          // ii. Call the Call internal method of callback with T as
          // the this value and argument list containing kValue, k, and O.
          callback.call(T, kValue, k, O);
        }
        // d. Increase k by 1.
        k++;
      }
      // 8. return undefined.
    };
  }

  if (window.NodeList && !NodeList.prototype.forEach) {
      NodeList.prototype.forEach = function (callback, thisArg) {
          thisArg = thisArg || window;
          for (var i = 0; i < this.length; i++) {
              callback.call(thisArg, this[i], i, this);
          }
      };
  }

  // In case the load event has already been fired, we start the init immediately.
  // We use document bc we want to reorder the dialog elements as soon as possible (window.load is fired too late)

  if (document.readyState == 'loading') {
    document.addEventListener("DOMContentLoaded", function(event) {
      initDialogs()
    });
  } else {
    initDialogs()
  }

  function initDialogs() {
    var dialogContainers = document.getElementsByClassName('j-dialog-container');
    var dialogs = [];
    var counter = 0;

    // Elements are removed from dialogContainers, so cycle for-loop backwards...
    for (var i = dialogContainers.length-1; i >= 0; i--) {
      document.body.appendChild(dialogContainers.item(i));
    }

    // Fill up again
    dialogContainers = document.getElementsByClassName('j-dialog-container');

    for (var i = 0; i < dialogContainers.length; i++) {
      dialogs[counter] = new A11yDialog(dialogContainers[i]);
      // console.log(counter);
      counter++;
    }

    // Update src of iframe with link url
    var panoramaTeaser = document.querySelectorAll(".panorama-teaser a");

    panoramaTeaser.forEach(function(teaser) {
      teaser.addEventListener("click", function(event){
        // Disable the links now that the modals are in place
        event.preventDefault();
        var myFrame = document.getElementById(this.getAttribute('data-a11y-dialog-show')).getElementsByTagName('iframe')[0];
        myFrame.setAttribute("src", this.getAttribute('data-iframe-url'));

        // Remove iFrame source on close
        var closeButtons = document.querySelectorAll(".j-dialog-close");
        closeButtons.forEach(function(button) {
          button.addEventListener("click", function(event){
            event.target.parentNode.getElementsByTagName('iframe')[0].setAttribute("src", "");
          });
        });
      });
    });

  }

})();
