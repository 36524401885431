/**
 *    Copyright 2022 Beaufort 8 GmbH

   Licensed under the Apache License, Version 2.0 (the "License");
   you may not use this file except in compliance with the License.
   You may obtain a copy of the License at

       http://www.apache.org/licenses/LICENSE-2.0

   Unless required by applicable law or agreed to in writing, software
   distributed under the License is distributed on an "AS IS" BASIS,
   WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
   See the License for the specific language governing permissions and
   limitations under the License.

 */
(function() {
	'use strict';

	ExtensionMap.prototype._forEach = function(array, callback, scope) {
		for (var i = 0; i < array.length; i++)
		{
			callback.call(scope, i, array[i]); // passes back stuff we need
		}
	};

	ExtensionMap.prototype._init = function() {
		var _this = this;
		let b = "--ratio: " + _this.ratio;
		_this.mapOuter.setAttribute('style', b);

		if (window.innerWidth >= this.options.breakpoint)
		{
			this.isMobile = false;
		}
		else
		{
			this.isMobile = true;
		}

		if (!this.isMobile)
		{
			this.controls.appendChild(
				document.querySelector('.extension-map-detailinfo')
			);
			this._addEventListeners(_this);
		}
		else
		{
			if (this.mapInner)
			{
				this.mapInner.appendChild(
					document.querySelector('.extension-map-detailinfo')
				);
			}
			else
			{
				this.map.appendChild(
					document.querySelector('.extension-map-detailinfo')
				);
			}
			this._addEventListeners(_this);
		}

		var resizeExtensionMap = this._debounce(function() {
			// This gets delayed for performance reasons

			_this.ratio = _this.mapBackgroundWidth / window.innerWidth;
			_this.mapOuter.style.setProperty('--ratio', _this.ratio);

			if (window.innerWidth >= _this.options.breakpoint)
			{
				_this.isMobile = false;
				_this.controls.appendChild(
					document.querySelector('.extension-map-detailinfo')
				);
			}
			else
			{
				_this.isMobile = true;
				_this.map.appendChild(
					document.querySelector('.extension-map-detailinfo')
				);
			}
		}, 50);

		window.addEventListener('resize', resizeExtensionMap);
	};

	ExtensionMap.prototype._initMap = function(_this) {
	};

	ExtensionMap.prototype._switchMap = function(id) {
		let _this = this;

		// scroll down animation
		$('html,body').animate({scrollTop: $("#extension-map .inner").offset().top - 60}, 'slow');
		// Activate map
		this._forEach(this.maps, function(index, value) {
			// check if is quality ranking stuff
			if (value.dataset.mapId != null)
			{
				if (value.dataset.mapId.toLowerCase() == id.toLowerCase())
				{
					value.classList.add('active');
					value.classList.remove('visually-hidden');
					_this.mapOuter.setAttribute('data-active', id.toLowerCase());
					_this.mapinfos[index].classList.add('active');
					_this.mapinfos[index].classList.remove('visually-hidden');

					let transformValue = value.dataset.transformDesktop

					if (window.screen.width < 578)
					{
						transformValue = value.dataset.transformMobile
					}
					else if (window.screen.width < 992)
					{
						transformValue = value.dataset.transformTablet
					}

					console.log(transformValue)

					if (transformValue === undefined)
					{
						_this.mapOuter.style.removeProperty('transform')
						_this.mapOuter.style.removeProperty('transform-origin')
					} else {
						_this.mapOuter.style.transform = transformValue
						_this.mapOuter.style.transformOrigin = 'top left'
					}

					_this.mapinfos[index].hidden = false;
				}
				else
				{
					value.classList.remove('active');
					value.classList.add('visually-hidden');
					_this.mapinfos[index].classList.remove('active');
					_this.mapinfos[index].classList.add('visually-hidden');
					_this.mapinfos[index].hidden = true;
				}
			}
			else
			{
				// check if is not quality ranking stuff
				if (index == id)
				{
					value.classList.add('active');
					value.classList.remove('visually-hidden');
					_this.mapOuter.setAttribute('data-active', id);
					_this.mapinfos[index].classList.add('active');
					_this.mapinfos[index].classList.remove('visually-hidden');
					_this.mapinfos[index].hidden = false;
				}
				else
				{
					value.classList.remove('active');
					value.classList.add('visually-hidden');
					_this.mapinfos[index].classList.remove('active');
					_this.mapinfos[index].classList.add('visually-hidden');
					_this.mapinfos[index].hidden = true;
				}
			}
		});
	};

	ExtensionMap.prototype._addEventListeners = function(_this) {
		const mapSelector = document.getElementById('map-selection');
		mapSelector.addEventListener('change', e => {
			let selectedID = e.target.value;
			let selectedDataID = e.target.options[e.target.selectedIndex].getAttribute('data-id');

			if (selectedDataID != null)
			{
				this._switchMap(selectedDataID);
			}
			else
			{
				this._switchMap(selectedID);
			}
		});
	};

	// Returns a function, that, as long as it continues to be invoked, will not
	// be triggered. The function will be called after it stops being called for
	// N milliseconds. If `immediate` is passed, trigger the function on the
	// leading edge, instead of the trailing.
	ExtensionMap.prototype._debounce = function(func, wait, immediate) {
		var timeout;
		return function() {
			var context = this,
				args = arguments;
			var later = function() {
				timeout = null;
				if (!immediate)
				{
					func.apply(context, args);
				}
			};
			var callNow = immediate && !timeout;
			clearTimeout(timeout);
			timeout = setTimeout(later, wait);
			if (callNow)
			{
				func.apply(context, args);
			}
		};
	};

	// Pass in the objects to merge as arguments.
	// For a deep extend, set the first argument to `true`.
	ExtensionMap.prototype._extend = function() {
		// Variables
		var extended = {};
		var deep = false;
		var i = 0;
		var length = arguments.length;

		// Check if a deep merge
		if (
			Object.prototype.toString.call(arguments[0]) === '[object Boolean]'
		)
		{
			deep = arguments[0];
			i++;
		}

		// Merge the object into the extended object
		var merge = function(obj) {
			for (var prop in obj)
			{
				if (Object.prototype.hasOwnProperty.call(obj, prop))
				{
					// If deep merge and property is an object, merge properties
					if (
						deep &&
						Object.prototype.toString.call(obj[prop]) ===
						'[object Object]'
					)
					{
						extended[prop] = extend(
							true,
							extended[prop],
							obj[prop]
						);
					}
					else
					{
						extended[prop] = obj[prop];
					}
				}
			}
		};

		// Loop through each object and conduct a merge
		for (; i < length; i++)
		{
			var obj = arguments[i];
			merge(obj);
		}

		return extended;
	};

	var extensionMapContainer = document.getElementsByClassName(
		'j-extension-map-2019'
	)[0];

	if (extensionMapContainer != null)
	{
		var mapTrainload = new ExtensionMap();
	}

	function ExtensionMap(options)
	{
		this.options = this._extend(
			{
				breakpoint: 1024
			},
			options
		);

		this.isMobile = true;
		this.map = document.querySelector('.j-extension-map-2019');
		this.mapOuter = document.querySelector('.extension-map-container');
		this.mapInner = document.querySelector('.j-extension-map-2019 > .inner');
		this.mapBackground = document.querySelector('.extension-map-background-image');
		this.mapBackgroundWidth = this.mapBackground.getAttribute('width') || 1735;
		this.maps = document.querySelectorAll('.extension-map-layer');
		this.mapinfos = document.querySelectorAll('.extension-map-info');
		this.ratio = this.mapBackgroundWidth / window.innerWidth;
		this.controls = document.querySelector('.j-extension-map-controls');

		this._init();
	}
})();
