/**
 *    Copyright 2022 Beaufort 8 GmbH

   Licensed under the Apache License, Version 2.0 (the "License");
   you may not use this file except in compliance with the License.
   You may obtain a copy of the License at

       http://www.apache.org/licenses/LICENSE-2.0

   Unless required by applicable law or agreed to in writing, software
   distributed under the License is distributed on an "AS IS" BASIS,
   WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
   See the License for the specific language governing permissions and
   limitations under the License.

 */
(function () {
    'use strict';

    /**
     * @constructor
     *
     * @desc
     *  Filter for the magazine section of bwegt.de
     *
     * @param el
     *  The DOM node pointing to the filter element
     */
    function MagazineFilter(el) {

        this.filterSelect = document.querySelector('.js-filter-magazine-select');
        this.cardsGridMagazine = document.querySelector('.cards-grid--magazine');

        // Get the URL for a first filter in case a param is set
        this.url_string = window.location.href;
        this.url = new URL(this.url_string);
        
        if (!this.cardsGridMagazine) { 
            //console.log('No cards grid, stopping…');
            return; 
        }

        this.allCards = this.cardsGridMagazine.querySelectorAll('.card-magazine');
        
        if(this.allCards.length === 0) { 
            //console.log('No cards in the grid, stopping…');
            return; 
        }

        this.cardsGridMagazine.classList.add('cards-grid--filtered');
        for (let i = 0; i < this.allCards.length; i++) {
            this.allCards[i].classList.add('item-visible');
        }

        this.pagination = new $('.cards-grid--magazine').joldPaginator({
            'perPage': 9,
            'items': '.card',
            'paginator': '.pagination',
            'indicator': {
                'selector': '.paginationindicator',
                'text': 'Showing item {start}-{end} of {total}',
            }
        });

        this.preselect();
        this.recalculateSources();
        this.registerEvents();
    }

    /**
     * @desc
     *  Register events for the filter interactions
     */
    MagazineFilter.prototype.registerEvents = function () {
        //console.log("registerEvents()");
        let _this = this;
        this.filterSelect.addEventListener('change', function(event) {
			const selectedOption = event.target.options[event.target.selectedIndex];
			const selectedOptionText = selectedOption.textContent.replace(/\n/g, '');
			_mtm.push({event: 'dropdownselection', magazineFilterSelection: selectedOptionText});
			_this.updateCardsGrid(event.target.value)
		});
    }

    /**
     * @desc
     *  Invokes a filtering of the cacrds grid in case a URL filter param is set
     */
    MagazineFilter.prototype.preselect = function () {
        
        // Get the "filter" URL param
        var f = parseInt(this.url.searchParams.get("filter"));

        // And if it is an number and within the range of the select, update the cards grid
        if(Number.isInteger(f) && f > 0 && f < this.filterSelect.length) {
            this.filterSelect.value = f;
            this.updateCardsGrid(f)
        }
    }

    /**
     * @desc
     *  Updates the magazine cards grid based on the selection in the filter
     */
    MagazineFilter.prototype.updateCardsGrid = function (val) {
        //console.log("Selected: " + event.target.value);
        let selectedValue = val.toString();
        
        // Set the fiter param of the URL object
        this.url.searchParams.set('filter', selectedValue);
        var new_url = this.url.toString();
        // Update the browser address bar with the new URL
        window.history.pushState("object or string", "Title", new_url);    

        for (let i = 0; i < this.allCards.length; i++) {
            const element = this.allCards[i];
            
            if (element.hasAttribute('data-filter')) {
                var categories = element.getAttribute('data-filter').split(',');
                //console.log("categories: " + categories);

                if(categories.indexOf(selectedValue) != -1 || selectedValue == '0') {
                    element.removeAttribute('hidden');
                    element.classList.remove('item-hidden');
                    element.classList.add('item-visible');
                } else {
                    element.setAttribute('hidden', 'true');
                    element.classList.remove('item-visible');
                    element.classList.add('item-hidden');
                }
            }
        }

        this.recalculateSources();
    }

    /**
     * @desc
     *  Updates the image sources in the picture elements of large cards so that the right responsive image is used (landscape)
     */
	MagazineFilter.prototype.recalculateSources = function (){
		
        var visibleCards = document.querySelectorAll('.cards-grid--magazine .card-magazine--flexible:not([hidden=true])');
        //var largeCards = document.querySelectorAll('.card-magazine--flexible:first-child:not([hidden=true]), .card-magazine--flexible:nth-child(9n):not([hidden=true]), .card-magazine--flexible:nth-child(9n + 10):not([hidden=true]),.card-magazine--flexible:nth-child(9n + 4):not([hidden=true])');

		for (let b = 0; b < visibleCards.length; b++) {
            const card = visibleCards[b];
			if (card.hasAttribute('data-cached-source')) {
                card.classList.remove('card-magazine--large');
				const pictureElem = card.querySelector(".card-background picture");
				const firstSource = card.querySelector(".card-background picture source");
				const sourceToInsert = document.createElement("source");
				sourceToInsert.innerHTML = card.getAttribute('data-cached-source');
				pictureElem.insertBefore(sourceToInsert.querySelector('source'), pictureElem.firstChild);
				card.removeAttribute('data-cached-source');
            }

            let count = b + 1;
            if (count == 1 || count == 4 || (count - 4) % 9 == 0 || (count - 10) % 9 == 0 || count % 9 == 0) {
                const source = card.querySelector(".card-background picture source");
                card.setAttribute('data-cached-source', source.outerHTML);
                card.classList.add('card-magazine--large');
                source.remove();
            }
		}

		//large_cards.find('.card-background picture').find('source:first').remove();

		var homeCard = document.querySelector('.cards-grid--home .card-magazine--flexible:nth-child(9n + 5)');

		if(homeCard){
			const firstSource = homeCard.querySelector('.card-background picture source:first-child');
            const secondSource = homeCard.querySelector('.card-background picture source:nth-child(2)');
            secondSource.setAttribute('media', '(min-width: 64em)');
            const homeCardPicture = homeCard.querySelector('.card-background picture');
            homeCardPicture.insertBefore(secondSource, firstSource);
        }
        
        this.updateFilter();
    }

    /**
     * @desc
     *  Updates the image sources in the pciture elements of large cards so that the right responsive image is used (landscape)
     */
	MagazineFilter.prototype.updateFilter = function (){
        this.pagination.init();
    }

    var slice = Array.prototype.slice;

    // function $(expr, con) {
    //     return typeof expr === "string" ? (con || document).querySelector(expr) : expr || null;
    // }

    function $$(expr, con) {
        return slice.call((con || document).querySelectorAll(expr));
    }

    // Initialization

    function init() {
        $$(".js-filter-magazine-select").forEach(function (input) {
            new MagazineFilter(input);
        });
    }

    // Are we in a browser? Check for Document constructor
    if (typeof Document !== "undefined") {
        // DOM already loaded?
        if (document.readyState !== "loading") {
            init();
        } else {
            // Wait for it
            document.addEventListener("DOMContentLoaded", init);
        }
    }

    // Export on self when in a browser
    if (typeof self !== "undefined") {
        self.MagazineFilter = MagazineFilter;
    }

    // Expose as a CJS module
    if (typeof module === "object" && module.exports) {
        module.exports = MagazineFilter;
    }

    return MagazineFilter;

})();
