/**
 *    Copyright 2022 Beaufort 8 GmbH

   Licensed under the Apache License, Version 2.0 (the "License");
   you may not use this file except in compliance with the License.
   You may obtain a copy of the License at

       http://www.apache.org/licenses/LICENSE-2.0

   Unless required by applicable law or agreed to in writing, software
   distributed under the License is distributed on an "AS IS" BASIS,
   WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
   See the License for the specific language governing permissions and
   limitations under the License.

 */
(function() {
    "use strict";

    const section1 = document.querySelector(".journey-section--01");

    if(!section1) {
        return;
    }
    
    const section2 = document.querySelector(".journey-section--02");
    const section3 = document.querySelector(".journey-section--03");
    const section4 = document.querySelector(".journey-section--04");
    const section5 = document.querySelector(".journey-section--05");
    const section6 = document.querySelector(".journey-section--06");
    const section7 = document.querySelector(".journey-section--07");
    const section8 = document.querySelector(".journey-section--08");

    const options = {
        rootMargin: "0px",
        threshold: [
            0.1,
            0.15,
            0.2,
            0.25,
            0.3,
            0.35,
            0.4,
            0.45,
            0.5,
            0.55,
            0.6,
            0.65,
            0.7,
            0.75,
            0.8,
            0.85,
            0.9,
            0.95,
            1.0
        ]
    };

    gsap.registerPlugin(DrawSVGPlugin);

    const intersectionObserver = new IntersectionObserver(function(elements) {
        elements.forEach(function(element) {
            if (element.isIntersecting === true) {
                //element.target.classList.add("inview");
            } else {
                element.target.classList.remove("inview");
            }

            if (
                element.isIntersecting === true &&
                element.intersectionRatio > 0.4 &&
                !element.target.classList.contains("inview") &&
                !element.target.classList.contains("played")
            ) {
                let currentSectionClass = "";

                if (element.target.classList.contains("journey-section--01")) {
                    currentSectionClass = "journey-section--01";
                }

                if (element.target.classList.contains("journey-section--02")) {
                    currentSectionClass = "journey-section--02";
                }

                if (element.target.classList.contains("journey-section--03")) {
                    currentSectionClass = "journey-section--03";
                }

                if (element.target.classList.contains("journey-section--04")) {
                    currentSectionClass = "journey-section--04";
                    gsap.set("#zug-01", { opacity: 0, x: "50vw" });
                    gsap.set("#zug-02", { opacity: 0, x: "-50vw" });
                    gsap.to("#zug-01", 2, {
                        opacity: 1,
                        x: "0",
                        ease: " power3.Out"
                    });
                    gsap.to("#zug-02", 2, {
                        opacity: 1,
                        x: "0",
                        ease: " power3.Out",
                        delay: 0.3
                    });
                }

                if (element.target.classList.contains("journey-section--05")) {
                    currentSectionClass = "journey-section--05";
                }

                if (element.target.classList.contains("journey-section--06")) {
                    currentSectionClass = "journey-section--06";
                }

                if (element.target.classList.contains("journey-section--07")) {
                    currentSectionClass = "journey-section--07";
                    gsap.set("#bee-01", { opacity: 1, x: "30vw" });
                    gsap.set("#bee-02", { opacity: 1, x: "-50vw" });
                    gsap.to("#bee-01", 42, {
                        opacity: 1,
                        x: "-100vw",
                        ease: " power3.Out",
                        repeat: 4
                    });
                    gsap.to("#bee-02", 56, {
                        opacity: 1,
                        x: "100vw",
                        ease: " power3.Out",
                        delay: 3,
                        repeat: 4
                    });
                }

                if (element.target.classList.contains("journey-section--08")) {
                    currentSectionClass = "journey-section--08";
                }

                const sectionTrack =
                    "." + currentSectionClass + " .journey-section-track";
                const sectionTrackMobile =
                    "." +
                    currentSectionClass +
                    " .journey-section-track--mobile";
                const sectionSel =
                    "." + currentSectionClass + ' [class^="cls"]';
                const sectionImgSel =
                    "." + currentSectionClass + " .text-img-image img";
                const sectionSVGSel =
                    "." + currentSectionClass + " .text-img-image svg";
                const sectionTextSel =
                    "." + currentSectionClass + " .text-img-text";
                element.target.classList.add("inview");
                element.target.classList.add("played");

                // Animate paths
                //let svg = element.target.querySelectorAll(".cls-1");
                gsap.set(sectionSel, { drawSVG: "0% " });
                //gsap.set(sectionSel, {  });
                gsap.to(sectionSel, 2, { opacity: 1, ease: " power3.inOut" });
                gsap.to(sectionSel, 5, {
                    drawSVG: "100% ",
                    ease: " power3.inOut"
                });

                gsap.to(sectionTrack, 1, { opacity: 1, ease: " power3.inOut" });
                gsap.to(sectionTrackMobile, 1, {
                    opacity: 1,
                    ease: " power3.inOut"
                });

                // Animate images
                gsap.set(sectionImgSel, { y: "30px" });
                gsap.to(sectionImgSel, 1, {
                    opacity: 1,
                    y: "0px",
                    ease: " power3.inOut",
                    delay: 0.5
                });
                gsap.to(sectionSVGSel, 1, {
                    opacity: 1,
                    y: "0px",
                    ease: " power3.inOut",
                    delay: 0.5
                });

                // Animate text
                gsap.set(sectionTextSel, { y: "30px" });
                gsap.to(sectionTextSel, 1, {
                    opacity: 1,
                    y: "0px",
                    ease: " power3.inOut",
                    delay: 0.3
                });
            } else {
                return;
            }
        });
    }, options);
    // start observing
    intersectionObserver.observe(section1);
    intersectionObserver.observe(section2);
    intersectionObserver.observe(section3);
    intersectionObserver.observe(section4);
    intersectionObserver.observe(section5);
    intersectionObserver.observe(section6);
    intersectionObserver.observe(section7);
    intersectionObserver.observe(section8);

})();
