/**
 *    Copyright 2022 Beaufort 8 GmbH

   Licensed under the Apache License, Version 2.0 (the "License");
   you may not use this file except in compliance with the License.
   You may obtain a copy of the License at

       http://www.apache.org/licenses/LICENSE-2.0

   Unless required by applicable law or agreed to in writing, software
   distributed under the License is distributed on an "AS IS" BASIS,
   WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
   See the License for the specific language governing permissions and
   limitations under the License.

 */
(function() {
    'use strict';

    /*Variables*/
    const form = document.querySelector('.form-toubiz--event');
    let buttonToday,
    buttonTomorrow,
    buttonWeekend,
    buttonSubmit,
    startTime,
    endTime,
    startPicker,
    endPicker,
    weekendSat,
    weekendSun,
    weekendStart,
    weekendEnd,
    timeButtons;

    let state = false;

    let flatpickrdate = {
        firstDayOfWeek: 1,
        weekAbbreviation: "KW",
        rangeSeparator: " bis ",
        scrollTitle: "Zum Ändern scrollen",
        toggleTitle: "Zum Umschalten klicken",
        weekdays: {
            shorthand: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
            longhand: ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"]
        },
        months: {
            shorthand: ["Jan", "Feb", "Mär", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"],
            longhand: ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"]
        }
    };
    

    const init = () => {
        if(form == null) {
            return false;
        }

        loadInputs();
        listeners();
        if(form.length > 0) {
            const scriptURL = "VENDORJSPATH/flatpickr.min.js";
            window.loadScript(scriptURL, setButtonDate);
        }
    }

    const listeners = () => {
        timeButtons.forEach(button => {
                    
            button.addEventListener('click', (e) => {
                timeButtons.forEach(button => {
                    button.classList.remove('is-clicked');
                });
                e.currentTarget.classList.add('is-clicked');
            });
        });

        buttonToday.addEventListener('click', (e) => {
            const today = new Date()
            startPicker.destroy();
            endPicker.destroy();
            state = true;
            setButtonDate(today, today);
        });

        buttonTomorrow.addEventListener('click', (e) => {
            const today = new Date()
            const tomorrow = new Date(today)
            tomorrow.setDate(tomorrow.getDate() + 1);
            startPicker.destroy();
            endPicker.destroy();
            state = true;
            setButtonDate(tomorrow, tomorrow);
        });

        buttonWeekend.addEventListener('click', (e) => {
            weekendSat = new Date().getDate() - (new Date().getDay() - 1) + 5;
            weekendStart = new Date(new Date().setDate(weekendSat));
            weekendSun = new Date().getDate() - (new Date().getDay() - 1) + 6;
            weekendEnd = new Date(new Date().setDate(weekendSun));
            startPicker.destroy();
            endPicker.destroy();
            state = true;
            setButtonDate(weekendStart, weekendEnd);
        });
    }

    const setButtonDate = (startDate, endDate) => {
        flatpickr.localize(flatpickr.l10ns.de);

        if(state != false) {
            if(startDate != undefined) {
                startTime = startDate;
            } else {
                startTime = "today"
            }
        }

        startPicker = flatpickr(".j-date-picker--start", {
            altInput: true,
            altFormat: "D, d.m.y",
            locale: flatpickrdate,
            defaultDate: startTime,
            minDate: "today",
            allowInput:true,
            onValueUpdate: function(selectedDates, dateStr, instance) {
				if (this.selectedDates[0] > endPicker.selectedDates[0]) {
					endPicker.setDate(this.selectedDates);
				}
        	},
        onOpen: function(selectedDates, dateStr, instance){
            this.redraw();
        }
        });
	
        if(state != false) {
            if(endDate != undefined) {
                endTime = endDate
            } else {
                endTime = "today"
            }
        }

        endPicker = flatpickr(".j-date-picker--end", {
            altInput: true,
            altFormat: "D, d.m.y",
            locale: flatpickrdate,
            defaultDate: endTime,
            minDate: "today",
            allowInput:true,
            onValueUpdate: function(selectedDates, dateStr, instance) {
				if (this.selectedDates[0] < startPicker.selectedDates[0]) {
					startPicker.setDate(this.selectedDates);
				}
        	},
        onOpen: function(selectedDates, dateStr, instance){
            this.redraw();
        }
        });
    }

    const loadInputs = () => {
        buttonToday  = form.querySelector('.js-button-today');
        buttonTomorrow   = form.querySelector('.js-button-tomorrow');
        buttonWeekend   = form.querySelector('.js-button-weekend');
        buttonSubmit = form.querySelector('button[type="submit"]');
        timeButtons = [buttonToday, buttonTomorrow, buttonWeekend];
    };

    init();
}());
