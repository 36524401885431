/**
 *    Copyright 2022 Beaufort 8 GmbH

   Licensed under the Apache License, Version 2.0 (the "License");
   you may not use this file except in compliance with the License.
   You may obtain a copy of the License at

       http://www.apache.org/licenses/LICENSE-2.0

   Unless required by applicable law or agreed to in writing, software
   distributed under the License is distributed on an "AS IS" BASIS,
   WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
   See the License for the specific language governing permissions and
   limitations under the License.

 */

/* bwegt b2b navigation */
(function () {
  if ( $('.b2b-nav').length > 0 ) {

    $('li.has-subnav > a').click(function (e) {
      e.preventDefault();
      $(this)
        .parent()
        .toggleClass('open');
    });

    var b2btoggle = document.querySelector('.b2b-nav .hamburger');

    if (b2btoggle) {
      b2btoggle.addEventListener('click', function (e) {

        if (
          b2btoggle.hasAttribute('aria-expanded') &&
          b2btoggle.getAttribute('aria-expanded') == 'true'
        ) {
          b2btoggle.setAttribute('aria-expanded', 'false');
			$(".b2b-nav").removeClass("is-expanded");
        } else {
          b2btoggle.setAttribute('aria-expanded', 'true');
			$(".b2b-nav").addClass("is-expanded");
        }
      });
    }

    var activeNavItem = $('.b2b-nav .active');

    if (activeNavItem) {
      activeNavItem.parents('.has-subnav').addClass('open');
    }

    // Handle window resizes and reset state of nav

    var handleNavResize = debounce(function () {
      $('.site-navigation').removeClass('is-expanded');
      b2btoggle.setAttribute('aria-expanded', 'false');
      $('.hamburger').find('.label').text('Menü');
    }, 10);

    window.addEventListener('resize', handleNavResize);

    if (window.innerWidth > 767) {
      //$('.submenu').attr('aria-hidden', 'false');
      debounce(function () {
        // This gets delayed for performance reasons
        $('.submenu').attr('aria-hidden', 'false');

      }, 200);
    }

    // Returns a function, that, as long as it continues to be invoked, will not
    // be triggered. The function will be called after it stops being called for
    // N milliseconds. If `immediate` is passed, trigger the function on the
    // leading edge, instead of the trailing.
    function debounce(func, wait, immediate) {
      var timeout;
      return function () {
        var context = this, args = arguments;
        var later = function () {
          timeout = null;
          if (!immediate) {
            func.apply(context, args);
          }
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) { func.apply(context, args); }
      };
    }
  }
})();
