/**
 *    Copyright 2022 Beaufort 8 GmbH

   Licensed under the Apache License, Version 2.0 (the "License");
   you may not use this file except in compliance with the License.
   You may obtain a copy of the License at

       http://www.apache.org/licenses/LICENSE-2.0

   Unless required by applicable law or agreed to in writing, software
   distributed under the License is distributed on an "AS IS" BASIS,
   WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
   See the License for the specific language governing permissions and
   limitations under the License.

 */
(function() {
	// Get all the tooltip buttons
	var tooltips = document.querySelectorAll('[data-glossary-tooltip-content]');
  
	// Iterate over them
	Array.prototype.forEach.call(tooltips, function (tooltip) {
	  // Get the message from the data-content element
	  var message = tooltip.getAttribute('data-glossary-tooltip-content');
  
	  // Get the live region element
	  var liveRegion = tooltip.nextElementSibling;
  
	  // Toggle the message
	  tooltip.addEventListener('click', function () {
		  liveRegion.innerHTML = '';
		  window.setTimeout(function() {
			liveRegion.innerHTML = '<span class="glossary-tooltip-bubble">'+ message +'</span>';
		  }, 100);
	  });
  
	  // Close on outside click
	  document.addEventListener('click', function (e) {
		if (tooltip !== e.target) {
		  liveRegion.innerHTML = '';
		}                        
	  });
  
	  // Remove tooltip on ESC
	  tooltip.addEventListener('keydown', function (e) {
		if ((e.keyCode || e.which) === 27)
		liveRegion.innerHTML = '';
	  });
	  
	  // Remove on blur
	  tooltip.addEventListener('blur', function (e) {
		liveRegion.innerHTML = '';
	  });
	});
  }());
