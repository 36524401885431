/**
 *    Copyright 2022 Beaufort 8 GmbH

   Licensed under the Apache License, Version 2.0 (the "License");
   you may not use this file except in compliance with the License.
   You may obtain a copy of the License at

       http://www.apache.org/licenses/LICENSE-2.0

   Unless required by applicable law or agreed to in writing, software
   distributed under the License is distributed on an "AS IS" BASIS,
   WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
   See the License for the specific language governing permissions and
   limitations under the License.

 */
(function () {
	// Get all the tooltip buttons
	const tooltipClick = document.querySelectorAll('[data-action="tooltip-click"]');
	// get tooltip div
	const tooltipBox = document.querySelector('[data-role="tooltip"]');

	Array.prototype.forEach.call(tooltipClick, function (tooltip) {
		// Toggle the message
		if(tooltip) {
			tooltip.addEventListener('click', function () {
				window.setTimeout(function () {
					if(tooltipBox) {
						tooltipBox.classList.add('_show');
					}
				}, 100);
			});

			// Remove tooltip on ESC
			tooltip.addEventListener('keydown', function (e) {
				if ((e.keyCode || e.which) === 27)
					if(tooltipBox) {
						tooltipBox.classList.remove('_show');
					}

				if ((e.keyCode || e.which) === 32)
					e.preventDefault();
					if(tooltipBox) {
						tooltipBox.classList.toggle('_show');
					}
			});

			// Remove on blur
			tooltip.addEventListener('blur', function (e) {
				if(tooltipBox) {
					tooltipBox.classList.remove('_show');
				}
			});
		}

		// Close on outside click
		document.addEventListener('click', function (e) {
			if (tooltip !== e.target) {
				if(tooltipBox) {
					tooltipBox.classList.remove('_show');
				}
			}
		});
	})
	
}());
