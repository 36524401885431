/**
 *    Copyright 2022 Beaufort 8 GmbH

   Licensed under the Apache License, Version 2.0 (the "License");
   you may not use this file except in compliance with the License.
   You may obtain a copy of the License at

       http://www.apache.org/licenses/LICENSE-2.0

   Unless required by applicable law or agreed to in writing, software
   distributed under the License is distributed on an "AS IS" BASIS,
   WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
   See the License for the specific language governing permissions and
   limitations under the License.

 */
(function() {
    "use strict";

    const clampedTexts = document.querySelectorAll('.textcrop-readmore');

    /**
     * Counts the number of lines in a target element based on height and line-height
     */
    function countLines(target) {
        var style = window.getComputedStyle(target, null);
        var height = parseInt(style.getPropertyValue("height"));
        var font_size = parseInt(style.getPropertyValue("font-size"));
        var line_height = parseInt(style.getPropertyValue("line-height"));
        var box_sizing = style.getPropertyValue("box-sizing");
        
        if(isNaN(line_height)) line_height = font_size * 1.5;
       
        if(box_sizing=='border-box')
        {
          var padding_top = parseInt(style.getPropertyValue("padding-top"));
          var padding_bottom = parseInt(style.getPropertyValue("padding-bottom"));
          var border_top = parseInt(style.getPropertyValue("border-top-width"));
          var border_bottom = parseInt(style.getPropertyValue("border-bottom-width"));
          height = height - padding_top - padding_bottom - border_top - border_bottom
        }
        var lines = Math.ceil(height / line_height);
        return lines;
      }

    clampedTexts.forEach((text) => {
        const toggleButton = text.querySelector('button.showmore');
        const textBlockToClamp = text.querySelector('p:nth-of-type(1)');

        // Extra feature: Hide the show more button when the amount of text is too small to be clamped anyway

        // Get the number of lines for the text block
        let numLines = 0;
        text.classList.toggle('textcrop-readmore--open')
        numLines = countLines(textBlockToClamp);
        text.classList.toggle('textcrop-readmore--open')

        // Depending on the viewport width and the number of lines, hide the button
        const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
        let clamp = true;

        if (vw < 440 && numLines < 12 ||
            vw < 480 && numLines < 10 ||
            vw < 544 && numLines < 9 ||
            vw < 600 && numLines < 8 ||
            vw < 752 && numLines < 7 ||
            vw < 848 && numLines < 6 ||
            vw > 848 && numLines < 5) {
            clamp = false;
        }

        if(!clamp) {
            toggleButton.remove();
        }

        // In case the button is needed, add the event listener for toggling the content
        toggleButton.addEventListener('click', (e) => {
            text.classList.toggle('textcrop-readmore--open');
        })
    });

})();
