/**
 *    Copyright 2022 Beaufort 8 GmbH

   Licensed under the Apache License, Version 2.0 (the "License");
   you may not use this file except in compliance with the License.
   You may obtain a copy of the License at

       http://www.apache.org/licenses/LICENSE-2.0

   Unless required by applicable law or agreed to in writing, software
   distributed under the License is distributed on an "AS IS" BASIS,
   WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
   See the License for the specific language governing permissions and
   limitations under the License.

 */
(function () {

  if ( typeof window.CustomEvent === "function" ) return false;

  function CustomEvent ( event, params ) {
    params = params || { bubbles: false, cancelable: false, detail: null };
    var evt = document.createEvent( 'CustomEvent' );
    evt.initCustomEvent( event, params.bubbles, params.cancelable, params.detail );
    return evt;
   }

  window.CustomEvent = CustomEvent;
})();

/*jshint esversion: 6 */
(function () {
  'use strict';

  // Data container
  let remoteData = [];
  let currentQuery = "";
  const hiddenInputFrom = document.querySelector('#connectionform-from-id');
  const hiddenInputTo = document.querySelector('#connectionform-to-id');
  const hiddenInputChash = document.getElementById("cHash");
  var event = new CustomEvent('change');

  // Sets the IDs on hidden inputs from the selected auto-suggested value
  function onConfirmSetID(val) {

    if (document.querySelector('.autocomplete__input--focused') == document.querySelector('#connectionform-from')) {
      if (val) {
        let idFrom = val.ID_TP;
        hiddenInputFrom.value = idFrom;
        hiddenInputFrom.dispatchEvent(event);
        document.querySelector('#connectionform-from').classList.remove('has-errors');
      } else if (document.querySelector('#connectionform-from-container .autocomplete__option--no-results')) {
        hiddenInputFrom.value = "";
      }
    }

    if (document.querySelector('.autocomplete__input--focused') == document.querySelector('#connectionform-to')) {
      if (val) {
        let idTo = val.ID_TP;
        hiddenInputTo.value = idTo;
        hiddenInputTo.dispatchEvent(event);
        document.querySelector('#connectionform-to').classList.remove('has-errors');
      } else if (document.querySelector('#connectionform-to-container .autocomplete__option--no-results')) {
        hiddenInputTo.value = "";
      }
    }

    // If there are no errors left, remove the error message
    if (document.querySelectorAll('input.has-errors').length == 0) {
      if(errorContainer){
        errorContainer.classList.remove('has-errors');
        errorContainer.innerHTML = "";
      }
    }
  }

  // Overwrite default suggest function
  function customSuggest(query, syncResults) {

    /* Set currentQuery for later use, e.g. in suggestionTemplate */
    currentQuery = query;
    // Get the list of matching stations from the database… 
    //location.protocol + '//' + window.location.hostname
    window.fetch(location.protocol + '//' + window.location.hostname + '/?8bd07458ff77b6c8e2bc8912c6604890=61e753ad294f7a91b111ad28e53f4a0b&b9029fa64532cfa6e5672af4f4f86a21=abcfbcbec0c54f8df45b42241929f211&7132ace821e4d04e187d84428424f9cf=f7920e18fde19b43dcd3b0279572b049&d1e1f3f8a64abdf99cc7ebf220ea2f67={"stationname":"' + encodeURIComponent(query) + '"}')
    // window.fetch(location.protocol + '//' + window.location.hostname + '/index.php?eID=getstation&stationname=' + encodeURIComponent(query))
      .then(function (response) {
          // …make sure it is JSON…
          return response.json();
        })
        .then(function (data) {
          // … push it into a reset array container…
        // …do we still need this?
        remoteData = [];
        remoteData.push(data);
        let results = remoteData[0];

        // Set IDs as values for the hidden inputs - just in case the user does not select a suggestion but types the exact name into the input field (congrats!)
        if (results[0] && results[0].NAME_VOLL === currentQuery) {
          onConfirmSetID(results[0]);
        }
        return syncResults(results);
      });

  }

  // Overwrite default input value template function
  function inputValueTemplate(result) {
    if (result) {
      return result.NAME_VOLL;
    }
  }

  // Overwrite default suggestion template function
  function suggestionTemplate(result) {

    // Split the query string into an array of subterms
    const subterms = currentQuery.trim().split(/[\s\u0020\,\.\;\/\-\–\(\)]+/);

    function replacer(match) {
      return '<span class="highlight">' + match + '</span>';
    }

    // Construct our regex
    let regex = new RegExp("(" + subterms.join('|') + ")", "gi");

    // Wrap all occurrences in a <span>
    const stopName = result.NAME_VOLL.replace(regex, replacer);

    if (result) {
      return '<span class="stop-type stop-type--' + /*result.type +*/ '"></span><span class="stop-name">' + stopName + '</span><span class="stop-id">' + result.ID_TP + '</span>';
    }
  }

  // Init the two inputs

  if (document.querySelector('#connectionform-from-container')) {
    // Accessible Autocomplete: First input = from
    accessibleAutocomplete({
      element: document.querySelector('#connectionform-from-container'),
      id: 'connectionform-from', // To match it to the existing <label>.
      source: customSuggest,
      displayMenu: 'overlay',
      minLength: 0,
      required: true,
      name: 'connectionFrom',
      tNoResults: function () {
        return 'Keine Ziele gefunden';
      },
      templates: {
        inputValue: inputValueTemplate,
        suggestion: suggestionTemplate
      },
      onConfirm: onConfirmSetID
    });

    document.querySelector('#connectionform-from').addEventListener('input', function(e){
      hiddenInputFrom.value = "";
    });

    // Open the suggestions on focus: With a little hack, adding a zero-width space and removing it again to trigger auto-suggest
    document.querySelector('#connectionform-from').addEventListener('focus', function(e){
      let fromInput = document.querySelector('#connectionform-from');
      let tempVal = fromInput.value;
      if(tempVal.length > 0) {
        fromInput.value = tempVal + "​";
        setTimeout(() => {
          fromInput.value = tempVal;
        }, 100);
      }
    });
  }

  if (document.querySelector('#connectionform-to-container')) {
    // Accessible Autocomplete: Second input = to
    accessibleAutocomplete({
      element: document.querySelector('#connectionform-to-container'),
      id: 'connectionform-to', // To match it to the existing <label>.
      source: customSuggest,
      displayMenu: 'overlay',
      minLength: 0,
      required: true,
      name: 'connectionTo',
      tNoResults: function () {
        return 'Keine Ziele gefunden';
      },
      templates: {
        inputValue: inputValueTemplate,
        suggestion: suggestionTemplate
      },
      onConfirm: onConfirmSetID
    });

    document.querySelector('#connectionform-to').addEventListener('input', function(e){
      hiddenInputTo.value = "";
    });

    // Open the suggestions on focus: With a little hack, adding a zero-width space and removing it again to trigger auto-suggest
    document.querySelector('#connectionform-to').addEventListener('focus', function(e){
      let toInput = document.querySelector('#connectionform-to');
      let tempVal = toInput.value;
      if(tempVal.length > 0) {
        toInput.value = tempVal + "​";
        setTimeout(() => {
          toInput.value = tempVal;
        }, 100);
      }
    });
  }

  // a11y
  if(document.querySelector('#connectionform-from__listbox')) {
      document.querySelector('#connectionform-from__listbox').setAttribute('aria-label', 'Suchvorschläge Abfahrtsort');
  }

  if(document.querySelector('#connectionform-to__listbox')) {
      document.querySelector('#connectionform-to__listbox').setAttribute('aria-label', 'Suchvorschläge Zielort');
  }

  // Add data-attribute after first "touch" of input --> mainly for styling
  const inputs = document.querySelectorAll('.fareinfo-search-form-input input');

  if (inputs) {
    for (let i = 0; i < inputs.length; ++i) {
      inputs[i].addEventListener('blur', e => e.target.setAttribute('data-touched', 'true'));
    }
  }

  // Show an error when a user enters the same station twice
  const submitButton = document.querySelector('#fareinfo-search-form button[type=submit]');

  const fareinfoForm = document.querySelector('#fareinfo-search-form');
  const errorContainer = document.querySelector('#fareinfo-search-form .form-errors');

  if (fareinfoForm) {
    fareinfoForm.addEventListener("submit", function (event) {
      // Remove all former errors
      errorContainer.classList.remove('has-errors');
      errorContainer.innerHTML = "";

      let err = document.createElement("li");

      const inputFrom = document.querySelector('#connectionform-from');
      const inputTo = document.querySelector('#connectionform-to');

      // If both sations exist and share the same value, prevent form submission and show an error
      if (inputFrom.value !== '' && inputTo.value !== '' && inputFrom.value == inputTo.value) {
        event.preventDefault();

        let err = document.createElement("li");
        err.innerHTML = "<strong>Start und Ziel sind identisch.<br /> Bitte prüfen Sie Ihre Eingabe.</strong>";

        errorContainer.classList.add('has-errors');
        errorContainer.appendChild(err);
      }

      if (inputFrom.value !== '' && hiddenInputFrom.value == '' || inputTo.value !== '' && hiddenInputTo.value == '') {
        
        event.preventDefault();

        err.innerHTML = "<strong>Bitte wählen Sie eine Station aus der Vorschlagsliste.</strong>";
        errorContainer.classList.add('has-errors');
        errorContainer.appendChild(err);

        if (hiddenInputFrom.value == '') {
          inputFrom.classList.add('has-errors');
        }
        if (hiddenInputTo.value == '') {
          inputTo.classList.add('has-errors');
        }

        return;
      }

      submitButton.classList.add("is-spinning");

      const searchinfo = document.querySelector('.searchinfo');
      searchinfo.textContent = "Bitte haben Sie einen Moment Geduld. Ihre Verbindung wird berechnet…";

      // Reset spinner and hidden fields on page hide
      window.onpagehide = function(){
        if(submitButton.classList) {
          submitButton.classList.remove("is-spinning");
          searchinfo.textContent = "";
          hiddenInputFrom.value = "";
          hiddenInputTo.value = "";
          hiddenInputChash.value = "";
        }
      };

    }, false);
  }

  // Results: Toggle aria-hidden on change of the radio buttons for via-selection

  const viaToggles = document.querySelectorAll('.transit-waypoint-options input');
  const resultsLists = document.querySelectorAll('.fareinfo-search-results-list');

  for (let j=0; j < viaToggles.length; j++) {
    viaToggles[j].addEventListener('change', function(e){

      for (let k=0; k < resultsLists.length; k++) {
        resultsLists[k].setAttribute('aria-hidden', true);
        if(e.target.checked) {
          resultsLists[j].setAttribute('aria-hidden', false);
        }
      }
      
    })
  }
  
})();
