/**
 *    Copyright 2022 Beaufort 8 GmbH

   Licensed under the Apache License, Version 2.0 (the "License");
   you may not use this file except in compliance with the License.
   You may obtain a copy of the License at

       http://www.apache.org/licenses/LICENSE-2.0

   Unless required by applicable law or agreed to in writing, software
   distributed under the License is distributed on an "AS IS" BASIS,
   WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
   See the License for the specific language governing permissions and
   limitations under the License.

 */

(function() {
	'use strict';

	/**
	 * Variables.
	 */
	const navContainer = document.querySelector('.js-efa-sl3p-tabnav');
	const pathname = window.location.search;
	const languageSelect = $('.language-select');
	const languageOptions = $('.language-options');
	let currentLanguage = 'de';

	const isSelectorSupported = selector => {

		try {
			document.querySelector(selector);
			return true;

		} catch (error) {
			return false;
		}
	}

	const updateLanguageCheckboxes = () => {
		// de is the default
		let currentLanguageItem = document.querySelector("[data-lang=de]");

		if (pathname.includes("lng=en")) {
			currentLanguageItem = document.querySelector("[data-lang=en]");
		}
		currentLanguageItem.setAttribute('aria-current', true);
	}

	const changeLanguageFlags = () => {
		let flagGb = $('.language-select .flag.gb');
		let flagDe = $('.language-select .flag.de');

		if (pathname.includes("lng=en")) {
			flagDe.toggle();
			flagGb.toggle();

			const tripItem = document.querySelector("[data-applinktype=trip]")
			tripItem.href = tripItem.href.replace("lng=de", "lng=en");

			tripItem.querySelector("span").innerHTML = "Journey planner";

			const departureMonitorItem = document.querySelector("[data-applinktype=departureMonitor]")
			departureMonitorItem.href = departureMonitorItem.href.replace("lng=de", "lng=en");
			departureMonitorItem.querySelector("span").innerHTML = "Departure monitor";

			const stopTimetableItem = document.querySelector("[data-applinktype=stopTimetable]")
			stopTimetableItem.href = stopTimetableItem.href.replace("lng=de", "lng=en");
			stopTimetableItem.querySelector("span").innerHTML = "Stop timetable";
		}
	}

	languageSelect.click(function () {
		// de is the default
		if (pathname.includes("lng=en")) {
			currentLanguage = 'en';
		}

		// Replace lng query param
		const languageLinks = document.querySelectorAll('[data-lang]');
		languageLinks.forEach(link => {
			const linkLang = link.getAttribute('data-lang');
			link.href = window.location.toString().replace(`lng=${currentLanguage}`, `lng=${linkLang}`);
		})
	});

	const initEFANav = () => {
		if (navContainer == null)
		{
			return;
		}

		if (isSelectorSupported(":where(body)"))
		{
			document.documentElement.classList.add("supports-where");
			console.log("supports-where");
		}
		else
		{
			document.documentElement.classList.add("not-supports-where");
			console.log("not-supports-where");
		}

		window.addEventListener("load", () => {
			const navItems = navContainer.querySelectorAll('.efa-sl3p-tabnav-item');
			const pathname = window.location.pathname;

			navItems.forEach(item => {
				item.removeAttribute('aria-current');
			});

			// trip is the default
			let currentItem = document.querySelector("[data-applinktype=trip]");

			if (pathname.includes("/efa/app/departureMonitor"))
			{
				// departureMonitor
				currentItem = document.querySelector("[data-applinktype=departureMonitor]");
			}
			else if (pathname.includes("/efa/app/stopTimetable"))
			{
				// stopTimetable
				currentItem = document.querySelector("[data-applinktype=stopTimetable]");
			}

			currentItem.setAttribute('aria-current', true);

			changeLanguageFlags();
			updateLanguageCheckboxes();
		});

	}

	initEFANav();

	// Language select
	languageSelect.click(function(){
		languageOptions.toggleClass('active');
	});

})();
