(function () {
	if (document.querySelector('.home-header-slider-wrapper') !== null) {

		// Make the whole card clickable
		let slides = document.querySelectorAll(
			".home-header-slider-wrapper .home-slider-item"
		);

		for (let ct = 0; ct < slides.length; ct++) {
			const col = slides[ct];

			if (
				col.getAttribute("data-nojslink") !== null &&
				col.getAttribute("data-nojslink") !== "1"
			) {
				col.addEventListener("click", (e) => {
					if (e.currentTarget.querySelector("a") !== null) {
						let href = e.currentTarget
							.querySelector("a")
							.getAttribute("href");
						window.location.href = href;
					}
				});
			}
		}

		let sliderElements = document.querySelectorAll(
			".home-header-slider-wrapper"
		);
		let sliders = [];
		let initsliders = function () {

			sliderElements = document.querySelectorAll(
				".home-header-slider-wrapper"
			);

			for (let s = 0; s < sliderElements.length; s++) {
				const col = sliderElements[s];

				let slider = tns({
					container: col,
					items: 1,
					lazyload: true,
					controls: true,
					autoplay: false,
					autoplayButton: false,
					autoplayButtonOutput: false,
					speed: 1200,
					autoplayTimeout: 8000,
					autoplayHoverPause: true,
					touch: true,
					mouseDrag: true,
					arrowKeys: false,
					gutter: 32,
					nav: true,
					autoHeight: false,
					navPosition: "bottom",
					// controlsText: ["<button class='slick-prev'><img src='typo3conf/ext/basicdistribution/Resources/Public/Icons/Symbols/arrow-circle-yellow-left.svg' alt='arrow-left'></button>", "<button class='slick-next'><img src='typo3conf/ext/basicdistribution/Resources/Public/Icons/Symbols/arrow-circle-yellow-right.svg' alt='arrow-right'></button>"],
					controlsText: ["<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" width=\"43\" height=\"43\" viewBox=\"0 0 43 43\">\n" +
					"<title>vorige</title>\n" +
					"  <defs>\n" +
					"    <clipPath id=\"clip-bwegt_Pfeil_Interaktion\">\n" +
					"      <rect width=\"43\" height=\"43\"/>\n" +
					"    </clipPath>\n" +
					"  </defs>\n" +
					"  <g id=\"bwegt_Pfeil_Interaktion\" clip-path=\"url(#clip-bwegt_Pfeil_Interaktion)\">\n" +
					"    <g id=\"accordeon-circle-down-yellow\" transform=\"translate(-0.468 43.468) rotate(-90)\">\n" +
					"      <g id=\"circle-yellow\">\n" +
					"        <g id=\"Ellipse_8\" data-name=\"Ellipse 8\" transform=\"translate(0.468 0.468)\" fill=\"#fc0\" stroke=\"#fff\" stroke-width=\"1\">\n" +
					"          <circle cx=\"21.5\" cy=\"21.5\" r=\"21.5\" stroke=\"none\"/>\n" +
					"          <circle cx=\"21.5\" cy=\"21.5\" r=\"21\" fill=\"none\"/>\n" +
					"        </g>\n" +
					"      </g>\n" +
					"      <g id=\"control-arrow-down-grey\" transform=\"translate(16.117 18.917)\">\n" +
					"        <path id=\"Pfad_1472\" data-name=\"Pfad 1472\" d=\"M-18340.3,7809.252l5.76,6.1,5.943-6.1\" transform=\"translate(18340.303 -7809.252)\" fill=\"none\" stroke=\"#3a4044\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"1\"/>\n" +
					"      </g>\n" +
					"    </g>\n" +
					"  </g>\n" +
					"</svg>\n", "<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" width=\"43\" height=\"43\" viewBox=\"0 0 43 43\">\n" +
					"<title>nächste</title>\n" +
					"  <defs>\n" +
					"    <clipPath id=\"clip-bwegt_Pfeil_Interaktion\">\n" +
					"      <rect width=\"43\" height=\"43\"/>\n" +
					"    </clipPath>\n" +
					"  </defs>\n" +
					"  <g id=\"bwegt_Pfeil_Interaktion\" clip-path=\"url(#clip-bwegt_Pfeil_Interaktion)\">\n" +
					"    <g id=\"accordeon-circle-down-yellow\" transform=\"translate(-0.468 43.468) rotate(-90)\">\n" +
					"      <g id=\"circle-yellow\">\n" +
					"        <g id=\"Ellipse_8\" data-name=\"Ellipse 8\" transform=\"translate(0.468 0.468)\" fill=\"#fc0\" stroke=\"#fff\" stroke-width=\"1\">\n" +
					"          <circle cx=\"21.5\" cy=\"21.5\" r=\"21.5\" stroke=\"none\"/>\n" +
					"          <circle cx=\"21.5\" cy=\"21.5\" r=\"21\" fill=\"none\"/>\n" +
					"        </g>\n" +
					"      </g>\n" +
					"      <g id=\"control-arrow-down-grey\" transform=\"translate(16.117 18.917)\">\n" +
					"        <path id=\"Pfad_1472\" data-name=\"Pfad 1472\" d=\"M-18340.3,7809.252l5.76,6.1,5.943-6.1\" transform=\"translate(18340.303 -7809.252)\" fill=\"none\" stroke=\"#3a4044\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"1\"/>\n" +
					"      </g>\n" +
					"    </g>\n" +
					"  </g>\n" +
					"</svg>\n"],
				});
				sliders.push(slider);
			}
		};

		initsliders();
	}
})();