/**
 *    Copyright 2022 Beaufort 8 GmbH

   Licensed under the Apache License, Version 2.0 (the "License");
   you may not use this file except in compliance with the License.
   You may obtain a copy of the License at

       http://www.apache.org/licenses/LICENSE-2.0

   Unless required by applicable law or agreed to in writing, software
   distributed under the License is distributed on an "AS IS" BASIS,
   WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
   See the License for the specific language governing permissions and
   limitations under the License.

 */
function EventSearchForm() {
    Utilities.call(this);

    $('#event-search-form').submit(function(e) {
        e.preventDefault();
        $.get(
            'index.php?eID=getevents', {
                'eventType': $('#event-type').val(),
                'eventLocation': $('#event-location').val(),
                'eventLocationRadius': $('#event-location-radius').val(),
                'eventDateStart': $('#event-date-start').val(),
                'eventDateEnd': $('#event-date-end').val()
            },
            function(_data) {
                if (_data !== 'false') {
                    console.log(_data);
                    localStorage.setItem('eventData', _data);
                } else {
                    console.log('ende');
                }
            }
        );
    });
}

EventSearchForm.prototype = Object.create(Utilities.prototype);
EventSearchForm.prototype.init = function() {
    this.init();
};

var _eventPaginator = function() {
    $('#page-selection').bootpag({
        total: 5
    }).on("page", function(event, num) {
        $("#content").html("Page " + num); // or some ajax content loading...

        // ... after content load -> change total to 10
        $(this).bootpag({ total: 10, maxVisible: 10 });
    });
};

/* Class Utilities */
function Utilities() {

    this.rebuildString = function(_from, _to, _mark) {
        $('body').highlight(
            _from, {
                className: _mark
            }
        );
        $('.' + _mark).each(function() {
            if ($(this).parents('.footer-accordion-article').length <= 0) {
                $(this).html(_to);
            }
        });
    };

    this.isMobile = function() {
        return $(window).width() < 769 ? true : false;
    };

    this.getLang = function() {
        return $('html').attr('lang');
    };

    this.detectiveBrowser = function() {
        return navigator.detectiveBrowser = (function() {
            var ua = navigator.userAgent,
                tem = '',
                M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
            if (/trident/i.test(M[1])) {
                tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
                return 'IE ' + (tem[1] || '');
            }
            if (M[1] === 'Chrome') {
                tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
                if (tem != null) {
                    return tem.slice(1).join(' ').replace('OPR', 'Opera');
                }
            }
            M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
            if ((tem = ua.match(/version\/(\d+)/i)) != null) {
                M.splice(1, 1, tem[1]);
            }
            return M.join(' ');
        })();
    };

    this.isElementInViewport = function(_el) {
        var elementTop = _el.offset().top;
        var elementBottom = elementTop + _el.outerHeight();
        var viewportTop = $(window).scrollTop();
        var viewportBottom = viewportTop + $(window).height();

        return elementBottom > viewportTop && elementTop < viewportBottom;
    };


    this.hasElement = function(_element) {
        if (_element.length > 0 && typeof _element !== 'undefined') {
            return true;
        }
        return false;
    };

    this.get = function(_item) {
        return localStorage.getItem(_item);
    };

    this.set = function(_item, _value) {
        localStorage.setItem(_item, _value);
    };

    this.remove = function(_item) {
        localStorage.removeItem(_item);
    };

    this.goto = function(_url) {
        window.location.href = _url;
    };
}
/* /Class Utilities */

function Dom() {
    Utilities.call(this);
}

Dom.prototype = Object.create(Utilities.prototype);

