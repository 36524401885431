/**
 *    Copyright 2022 Beaufort 8 GmbH

   Licensed under the Apache License, Version 2.0 (the "License");
   you may not use this file except in compliance with the License.
   You may obtain a copy of the License at

       http://www.apache.org/licenses/LICENSE-2.0

   Unless required by applicable law or agreed to in writing, software
   distributed under the License is distributed on an "AS IS" BASIS,
   WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
   See the License for the specific language governing permissions and
   limitations under the License.

 */
(function () {
    'use strict';

    // This "fixes" the pagination of tx-indexedsearch so that it matches our pattern and is more accessible 

    // Get all pagination list items
    const pages = document.querySelectorAll(".tx-indexedsearch-browsebox li");

    for(let i=0; i < pages.length; i++) {
        //console.log(pages[i]);
        let current = pages[i];

        // Only if not the first or last item unless it is the current page
        if(current.classList.contains('tx-indexedsearch-browselist-currentPage') || i > 0 && i < pages.length - 1) {
            // Get the link
            let currentLink = current.querySelector('a');
            // Take the link text and add this as the aria-label
            currentLink.setAttribute('aria-label', currentLink.innerHTML);
            // and use only the number as the new link text
            if(currentLink.innerHTML.match(/\d+/) != null) {
                currentLink.innerHTML = currentLink.innerHTML.match(/\d+/)[0];
            }
        }
    }

})();
