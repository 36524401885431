/**
 *    Copyright 2022 Beaufort 8 GmbH

   Licensed under the Apache License, Version 2.0 (the "License");
   you may not use this file except in compliance with the License.
   You may obtain a copy of the License at

       http://www.apache.org/licenses/LICENSE-2.0

   Unless required by applicable law or agreed to in writing, software
   distributed under the License is distributed on an "AS IS" BASIS,
   WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
   See the License for the specific language governing permissions and
   limitations under the License.

 */
(function () {
	"use strict";

	/**
	 * Variables.
	 */

	const careerContainer = document.querySelector(
			'[data-role="js-career-container"]'
		),
		searchContainer = document.querySelector(
			"#career-form-search-container"
		),
		resetFiltersButton = document.querySelector(
			".career-search__refresh button"
		);

	let inputSearchField = "career-form-search-input",
		searchInputName = "search_name",
		placeholderSearch = "Jobtitel, Aufgabenbereich, Standort",
		timeout = null,
		selectedArr = {
			"job-category": {
				name: [],
				numberSelectedInputs: [],
			},
			"employer-type": {
				name: [],
				numberSelectedInputs: [],
			},
			"job-location": {
				name: [],
				numberSelectedInputs: [],
			},
			"job-employer": {
				name: [],
				numberSelectedInputs: [],
			},
		};

	/**
	 * matomo tracking functions
	 */

		let selectedFilterJobLevelArray = [];
		$('input[type="checkbox"][name="job_level[]"]').on("click", function () {
			let dataName = $(this).attr("data-name");
			if ($(this).prop("checked")) {
				selectedFilterJobLevelArray.push(dataName);
			} else {
				let index = selectedFilterJobLevelArray.indexOf(dataName);
				if (index !== -1) {
					selectedFilterJobLevelArray.splice(index, 1);
				}
			}
		});

		let selectedJobCategoryArray = [];
		$('input[type="checkbox"][name="job_category[]"]').on("click", function () {
			let jobCategorydataName = $(this).attr("data-name");
			if ($(this).prop("checked")) {
				selectedJobCategoryArray.push(jobCategorydataName);
			} else {
				let index = selectedJobCategoryArray.indexOf(jobCategorydataName);
				if (index !== -1) {
					selectedJobCategoryArray.splice(index, 1);
				}
			}
		});


		let selectedEmployerArray = [];
		$('input[type="checkbox"][name="evu_folder_id[]"]').on("click", function () {
			let employerdataName = $(this).attr("data-name");
			if ($(this).prop("checked")) {
				selectedEmployerArray.push(employerdataName);
			} else {
				let index = selectedEmployerArray.indexOf(employerdataName);
				if (index !== -1) {
					selectedEmployerArray.splice(index, 1);
				}
			}
		});

		let selectedJobLocationArray = [];
		$('input[type="checkbox"][name="job_location[]"]').on("click", function () {
			let locationdataName = $(this).attr("data-name");
			if ($(this).prop("checked")) {
				selectedJobLocationArray.push(locationdataName);
			} else {
				let index = selectedJobLocationArray.indexOf(locationdataName);
				if (index !== -1) {
					selectedJobLocationArray.splice(index, 1);
				}
			}
		});



		$("#form-career").on("submit", function (event) {
			const careerinputValue = $("#career-form-search-input").val();
			_mtm.push({event: 'careerformdata', careersearchinput: careerinputValue, joblevel: selectedFilterJobLevelArray, jobcategory: selectedJobCategoryArray, employer: selectedEmployerArray, joblocation: selectedJobLocationArray})
		});

		/**
		 * Inits the form interactions.
		 */

		const initForm = () => {
			if (careerContainer == null) {
				return;
			}

			loadSearchAutoComplete();
			addListeners();
			inputListener();
			openFilters();
			clearFilters();
			searchInDropdown();
			submitOnEnter();
			anchor();
			showReset();
		};

		/**
		 * Load Search Auto Complete
		 */

		const loadSearchAutoComplete = () => {
			if (searchContainer) {
				accessibleAutocomplete({
					element: searchContainer,
					id: inputSearchField,
					displayMenu: "overlay",
					source: suggest,
					name: searchInputName,
					placeholder: placeholderSearch,
					showNoOptionsFound: false,
				});
			}
		};

		/**
		 * Anchor after user submit
		 */
		const addListeners = () => {
			if (resetFiltersButton) {
				resetFiltersButton.addEventListener("click", resetAllFilters);
			}
		};

		/**
		 * Add general event listeners
		 */
		const anchor = () => {
			let headerHeight = 60;

			if (careerContainer) {
				if (careerContainer.classList.contains("_has-search")) {
					if ($("#results") && window.innerHeight < 600) {
						$("html,body").animate({
								scrollTop: $("#results").offset().top - headerHeight,
							},
							1000
						);
					} else {
						if ($("#results2") && window.innerHeight < 600) {
							$("html,body").animate({
									scrollTop: $("#results2").offset().top - headerHeight,
								},
								1000
							);
						}
					}
				}
			}
		};

		/**
		 * Get filter selected value
		 */
		const inputListener = () => {
			$('[data-role="filter-options"] label').on(
				"click",
				"input",
				(event) => {
					const el = $(event.target);
					checkOrUncheck(el, "click");
					countSelected(el, "click");
				}
			);

			$(window).on("load", function () {
				if (getSearchParams("search_name") != undefined) {
					let string = getSearchParams("search_name").replace(/\+/g, " ");
					$("input#career-form-search-container").val(
						decodeURIComponent(string)
					);
					$("input#career-form-search-container").attr(
						"data-value",
						decodeURIComponent(string)
					);
				}
				$('[data-role="filter"]').each(function () {
					const inputs = $(this)
						.children('[data-role="filter-options"]')
						.find("li:not(.filter-search-dropdown):not(.clear-filter)");
					$(inputs).each(function () {
						const el = $(this).find("input");
						checkOrUncheck(el, "loop");
						countSelected(el, "loop");
					});
				});

				//hide search autocomplete when has value (on load event)
				if ($("input#career-form-search-container").attr("data-value")) {
					$("#career-form-search-container ul").css("display", "none");
				}

				anchor();
			});

			//show search autocomplete when keyup
			$("input#career-form-search-container").on("keyup", (event) => {
				const el = $(event.target);
				el.siblings().css("display", "block");
			});
		};

		/**
		 * checkOrUncheck checkbox
		 */

		const checkOrUncheck = (element, event) => {
			let inputID = element
				.closest('[data-role="filter-options"]')
				.prev()
				.children("input")
				.attr("id");
			if (element.is(":checked")) {
				clearFilterSearchInput(element);
				element
					.closest('[data-role="filter-options"]')
					.prev()
					.children("input")
					.val(element.attr("data-name"));
				element
					.closest('[data-role="filter-options"]')
					.prev()
					.children("input")
					.addClass("selected");
				selectedArr[`${inputID}`]["name"].push(element.attr("data-name"));
			} else {
				element.prop("checked", false);
				if (event != "loop") {
					clearFilterSearchInput(element);
					let index = selectedArr[`${inputID}`]["name"].indexOf(
						element.attr("data-name")
					);
					selectedArr[`${inputID}`]["name"].splice(index, 1);
				}
			}
		};

		/**
		 * count number of selected checkboxes and add on main input
		 */

		const countSelected = (element, event) => {
			let inputID = element
				.closest('[data-role="filter-options"]')
				.prev()
				.children("input")
				.attr("id");
			if (Object.keys(selectedArr[`${inputID}`]["name"]).length != 0) {
				element
					.closest('ul')
					.find('.clear-filter')
					.attr('aria-hidden', 'false');
				element
					.closest('[data-role="filter-options"]')
					.addClass("has-selection");
				if (Object.keys(selectedArr[`${inputID}`]["name"]).length > 1) {
					selectedArr[`${inputID}`]["numberSelectedInputs"] = ` (+ ${
						Object.keys(selectedArr[`${inputID}`]["name"]).length - 1
					})`;
				} else {
					selectedArr[`${inputID}`]["numberSelectedInputs"] = [];
				}
				element
					.closest('[data-role="filter-options"]')
					.prev()
					.children("input")
					.val(
						selectedArr[`${inputID}`]["name"].slice(-1).pop() +
						`${selectedArr[`${inputID}`]["numberSelectedInputs"]}`
					);
			} else {
				element
					.closest('ul')
					.find('.clear-filter')
					.attr('aria-hidden', 'false');
				element
					.closest('[data-role="filter-options"]')
					.removeClass("has-selection");
				if (event != "loop") {
					element
						.closest('[data-role="filter-options"]')
						.prev()
						.children("input")
						.val("");
					element
						.closest('[data-role="filter-options"]')
						.prev()
						.children("input")
						.removeClass("selected");
				}
			}
		};

		/**
		 * get search value and add into search input
		 */

		const getSearchParams = (k) => {
			var p = {};
			location.search.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (s, k, v) {
				p[k] = v;
			});
			return k ? p[k] : p;
		};

		/**
		 * submit form on enter
		 */

		const submitOnEnter = () => {
			$(document).keypress(function (e) {
				//use form id
				if (e.keyCode == 13) {
					$("#form-career").submit(); // use form i
				}
			});
		};

		/**
		 * Open/Close filters by clicking on input
		 */

		const openFilters = () => {
			$('[data-role="input"]').on("click", (event) => {
				const el = $(event.target);
				el.parent().siblings().toggleClass("_open");
				el.parent().toggleClass("_open");
				$('[data-role="input"]').each(function () {
					if (el.attr("id") != $(this).attr("id")) {
						$(this).parent().siblings().removeClass("_open");
						$(this).parent().removeClass("_open");
					}
				});
			});

			$("body").click(function (event) {
				if (!$(event.target).closest('[data-role="filter"]').length) {
					$('[data-role="filter-options"]').removeClass("_open");
					$('[data-role="input-wrapper"]').removeClass("_open");
				}
			});
		};

		/**
		 * Resets all filters and inputs
		 */
		const resetAllFilters = () => {
			clearAllFilters();
			clearSearchInput();
		};

		/**
		 * function to show reset-button
		 */
		const showReset = () => {
			$('#career-form-search-input').keypress(function () {
				$('.career-search__refresh').addClass('active');
			});
		};

		/**
		 * function to clear the main search input
		 */

		const clearSearchInput = () => {
			if (searchContainer) {
				$(searchContainer).val("");
				$("input#career-form-search-container").focus();
			}
		};

		/**
		 * function to clear search dropdown input if users click in a option and remove _hide/show classes
		 */

		const clearFilterSearchInput = (element) => {
			let targetRemove = element
				.closest('[data-role="filter-options"]')
				.find("li:not(.filter-search-dropdown):not(.clear-filter)");
			// clear dropdown input
			element
				.closest('[data-role="filter-options"]')
				.find('[data-role="search-dropdown"]')
				.val("");

			$(targetRemove).each(function () {
				$(this).removeClass("_hide");
				$(this).removeClass("_show");
			});
		};

		/**
		 * Clear filter checkboxes by clicking on button
		 */

		const clearFilters = () => {
			$('[data-action="clear-filter"]').on("click", (event) => {
				event.preventDefault();
				const target = $(event.target);
				let filterOptions = target
					.closest('[data-role="filter-options"]')
					.find("li:not(.filter-search-dropdown):not(.clear-filter)");
				target
					.parent()
					.attr('aria-hidden', 'true');
				target
					.closest('[data-role="filter-options"]')
					.removeClass("has-selection");
				$(filterOptions).each(function () {
					let el = $(this).find("input");
					let inputID = el
						.closest('[data-role="filter-options"]')
						.prev()
						.children("input")
						.attr("id");
					el.prop("checked", false);
					el.closest('[data-role="filter-options"]')
						.prev()
						.children("input")
						.val("");
					el.closest('[data-role="filter-options"]')
						.prev()
						.children("input")
						.removeClass("selected");
					selectedArr[`${inputID}`]["name"] = [];
				});
			});
		};

		const clearAllFilters = () => {
			console.log("clear all filters!");
			let filters = document.querySelectorAll('[data-role="filter-options"]');

			for (let f = 0; f < filters.length; f++) {
				const filter = filters[f];
				filter.querySelector('.clear-filter').setAttribute('aria-hidden', 'false')
				filter.classList.remove("has-selection");
				let filterOptions = filter.querySelectorAll(
					"li:not(.filter-search-dropdown):not(.clear-filter)"
				);
				for (let o = 0; o < filterOptions.length; o++) {
					const optionEl = filterOptions[o];
					let el = optionEl.querySelectorAll("input")[0];
					let inputID = $(el)
						.closest('[data-role="filter-options"]')
						.prev()
						.children("input")
						.attr("id");
					el.checked = false;
					$(el).removeAttr("checked");
					$(el)
						.closest('[data-role="filter-options"]')
						.prev()
						.children("input")
						.val("");
					$(el)
						.closest('[data-role="filter-options"]')
						.prev()
						.children("input")
						.removeClass("selected");
					selectedArr[`${inputID}`]["name"] = [];
				}
			}
		};

		/**
		 * if dropdown has more than 8 checkboxes enable users of searching on main input
		 */

		const searchInDropdown = () => {
			$('[data-role="search-dropdown"]').on("keyup", (event) => {
				const target = $(event.target);
				const dataToMatch = target
					.closest('[data-role="filter-options"]')
					.find("li:not(.filter-search-dropdown):not(.clear-filter)");
				$(dataToMatch).each(function () {
					if (
						$(this)
							.find("input")
							.attr("data-name")
							.toUpperCase()
							.indexOf(target.val().toUpperCase()) != -1
					) {
						$(this).addClass("_show");
						$(this).removeClass("_hide");
					} else {
						$(this).addClass("_hide");
						$(this).removeClass("_show");
					}
				});
			});
		};

		/**
		 * Fetches the stops from the backend depending on the user input.
		 *
		 * @param {string} query User Input
		 * @param {callback} syncResults autocomplete callback
		 */
		const suggest = (query, populateResults) => {
			clearTimeout(timeout);

			timeout = setTimeout(function () {
				let baseUrl =
					location.protocol +
					"//" +
					window.location.hostname +
					"/index.php?";
				let urlQuery =
					"eID=search-autocomplete&s=" + decodeURIComponent(query);

				window
					.fetch(baseUrl + urlQuery)
					.then(function (response) {
						return response.json();
					})
					.then(function (data) {
						let autoCompleteData = [];
						if (data != null) {
							data.forEach((item) => {
								autoCompleteData.push(item.name);
							});
						}
						return populateResults(autoCompleteData);
					})
					.catch((error) => {
						return populateResults([]);
					});
			}, 200);
		};

		initForm();
	})
	();
