/**
 *    Copyright 2022 Beaufort 8 GmbH

   Licensed under the Apache License, Version 2.0 (the "License");
   you may not use this file except in compliance with the License.
   You may obtain a copy of the License at

       http://www.apache.org/licenses/LICENSE-2.0

   Unless required by applicable law or agreed to in writing, software
   distributed under the License is distributed on an "AS IS" BASIS,
   WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
   See the License for the specific language governing permissions and
   limitations under the License.

 */

(function () {
	'use strict';
	
	function showGDPRNotice(elem, serviceName) {
		if(typeof UC_UI === 'undefined') { return; }

		$(elem).parent().css('position', 'relative');
		if(!$(elem).parent().find('.uc-gdpr-notice').length) {
			$('<div class="uc-gdpr-notice"><p>' + serviceName + ' können aus Datenschutzgründen nicht angezeigt werden. Um die Inhalte zu laden, aktivieren Sie den Service in Ihren <a href="javascript:void(0)" onclick="UC_UI.showSecondLayer();">Privatsphäre-Einstellungen</a>.</p></div>' ).insertAfter( elem );
		}
	}

	window.addEventListener("load", function(event) {
		setTimeout(() => {

			if(typeof UC_UI === 'undefined') { return; }

			// Google Maps
			// console.log('Google Map => ' + UC_UI.getServicesBaseInfo().find(data => (data.id === "S1pcEj_jZX")).consent.status);
			if (UC_UI.getServicesBaseInfo().find(data => (data.id === "S1pcEj_jZX")).consent.status === true) {
				$('#uc_google_map').attr('src', $('#uc_google_map').attr('src-data'));
			} else if (UC_UI.getServicesBaseInfo().find(data => (data.id === "S1pcEj_jZX")).consent.status === false) {
				showGDPRNotice('#uc_google_map', "Google Maps Karten");
			}

			// YouTube
			// console.log('youtube => ' + UC_UI.getServicesBaseInfo().find(data => (data.id === "BJz7qNsdj-7")).consent.status);
			if (UC_UI.getServicesBaseInfo().find(data => (data.id === "BJz7qNsdj-7")).consent.status === true) {
				$('.youtube-video').each(function() {
					$(this).attr('src', $(this).attr('data-src'));
				});
			} else if (UC_UI.getServicesBaseInfo().find(data => (data.id === "BJz7qNsdj-7")).consent.status === false) {
				showGDPRNotice('.youtube-video', "YouTube-Videos");
			}

			// Vimeo
			// console.log('vimeo => ' + UC_UI.getServicesBaseInfo().find(data => (data.id === "HyEX5Nidi-m")).consent.status);
			if (UC_UI.getServicesBaseInfo().find(data => (data.id === "HyEX5Nidi-m")).consent.status === true) {
				$('.vimeo-video').each(function() {
					$(this).attr('src', $(this).attr('data-src'));
				});
			} else if (UC_UI.getServicesBaseInfo().find(data => (data.id === "HyEX5Nidi-m")).consent.status === false) {
				showGDPRNotice('.vimeo-video', "Vimeo-Videos");
			}
			
		}, 2000);
	});

	if (!window.UC_UI || !UC_UI.isInitialized()) {
		window.addEventListener('UC_UI_INITIALIZED', function() {
			matomoConsentHandler()
		});
	} else {
		matomoConsentHandler()
	}

	function matomoConsentHandler(reloadWhenNoConsent = false) {
		if (UC_UI.getServicesBaseInfo().find(service => service.id === "u6fxocwTs").consent.status === true) {
			_paq.push(['setConsentGiven']);
		} else if (reloadWhenNoConsent) {
			window.location.reload();
		}
	}

	/**
	 * Updates content based on (changed) privacy settings
	 *
	 * @param   {event}  ucEvent   Custom event by Usercentrics, event name can be defined in the Usercentrics control panel
	 */
	window.addEventListener("ucEvent", function (e) {  
		if( e.detail && e.detail.event == "consent_status" && e.detail.action !== "onInitialPageLoad") {

			// Google Maps
			if (e.detail["Google Maps"] == true) {
				$('#uc_google_map').attr('src', $('#uc_google_map').attr('src-data'));
				$('#uc_google_map').parent().find('.uc-gdpr-notice').remove();
			} else if (e.detail["Google Maps"] == false) {
				$('#uc_google_map').removeAttr('src');
				showGDPRNotice('#uc_google_map', "Google Maps Karten");
			}

			// YouTube
			if (e.detail["YouTube Video"] == true) {
				$('.youtube-video').each(function() {
					$(this).attr('src', $(this).attr('data-src'));
				});
				$('.youtube-video').parent().find('.uc-gdpr-notice').remove();
			} else if (e.detail["YouTube Video"] == false) {
				$('.youtube-video').each(function() {
					$(this).removeAttr('src');
				});
				showGDPRNotice('.youtube-video', "YouTube-Videos");
			}

			// Vimeo
			if (e.detail["Vimeo"] == true) {
				$('.vimeo-video').each(function() {
					$(this).attr('src', $(this).attr('data-src'));
				});
				$('.vimeo-video').parent().find('.uc-gdpr-notice').remove();
			} else if (e.detail["Vimeo"] == false) {
				$('.vimeo-video').each(function() {
					$(this).removeAttr('src');
				});
				showGDPRNotice('.vimeo-video', "Vimeo-Videos");
			}

			// Matomo
			matomoConsentHandler(true)
		}
	});

})();
